define({
  learn: "\u5b66\u4e60",
  api_reference: "API \u53c2\u8003",
  system: "\u7cfb\u7edf",
  help: "\u5e2e\u52a9",
  about_dna_center: "\u5173\u4e8e",
  sign_out: "\u6ce8\u9500",
  developer_resource: "\u5f00\u53d1\u8d44\u6e90",
  tools: "\u5de5\u5177",
  make_a_wish: "\u6539\u8fdb\u5efa\u8bae",
  audit_logs: "Audit Logs",
  system_settings: "System Settings"
});
