define([], function() {
	var TOAST_TIMEOUT = 3000;
	var $el;
	var template = `
		<div class="toast-container snackbar-container">
			<div class="col-md-4 col-md-offset-4 snackbar"></div>
		</div>
	`;

	var Toast = function(msg) {
		if (!$el) {
			$el = $(template);
			$el.appendTo("body");
		}

		var remove = function() {
			$el.removeClass("open");
			setTimeout(function() {
				$el.remove();
				$el = null;
			}, 300);
		};

		var show = function() {
			$el.addClass("open");

			setTimeout(function() {
				remove();
			}, TOAST_TIMEOUT);
		};

		$el.find(".snackbar").text(msg);

		setTimeout(function() {
			show();
		}, 0);
	};


	return Toast;
});