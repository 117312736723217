define([
	"jquery",
	'core/utils/GVTask'
],	function($, GVTask)	{
	"use strict";
	return {
		getAllDevices : function(fn)	{
			$.ajax({
				url		:	window.API_URL_PREFIX +	"/network-device",
				type	:	"GET",
				success	:	function(data)	{
					fn(data.response);
				},
				 error	:	function(data)	{
					fn(data);
				}
			});
		},
		getDeviceById : function(id, fn)	{
			$.ajax({
				url		:	window.API_URL_PREFIX +	"/network-device/" + id,
				type	:	"GET",
				success	:	function(data)	{
					fn(data.response);
				},
				 error	:	function(data)	{
					fn(data);
				}
			});
		},
		deleteDevice: function(id) {
			var dfd			= new $.Deferred();
			var deleteTask 	= new GVTask( "/network-device/"+id, {
				//interval: 100, // optional.. default 500
				type : "DELETE"
			});
			// When task is started
			deleteTask.onstart	= function()  {
				// console.log(id +"[ device deletion started]");
			};
			// When task is successful
			deleteTask.onsuccess = function( status )  {
				dfd.resolve( status );
			};
			// When task fails.
			deleteTask.onfail = function( error ) {
				dfd.reject( error );
			};
			// Start task
			deleteTask.startDelete();
			return dfd.promise();
		},
		getAllDeviceInfo : function(offset, batchsize, sortCol, sortOrder, filter)	{
			var data  = {
				sortBy		:	sortCol || "hostname",
				order		: 	sortOrder	||	"asc",
				intoffset	:	offset,
				limit		:	batchsize
			};
			$.extend(data, filter);
			// console.log(data);
			var dfd = new $.Deferred();

			$.ajax({
				url		:	window.API_URL_PREFIX +	"/network-device/?"+ "limit=" + batchsize +	"&" + "offset=" + offset + "&"	+ "sortBy=" + sortCol + "&" + "sortOrder=" + sortOrder + "&" + filter.strarr,
				type	:	"GET",
				success	:	function(data)	{
					dfd.resolve(data.response);
				},
				 error	:	function(data)	{
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		getDeviceCount : function(filter)	{
			var dfd = new $.Deferred();
			$.ajax({
				url		:	window.API_URL_PREFIX +	"/network-device/count",
				type	:	"GET",
				success	:	function(data)	{
					dfd.resolve(data.response);
				},
				 error	:	function(data)	{
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		autocomplete : function(colId, term)	{
			var dfd = new $.Deferred();
			$.ajax({
				url		:	window.API_URL_PREFIX +"/network-device?autocompleteField="+colId+"&criteria="+term+"&limit=20&offset=1",
				type	:	"GET",
				success	:	function(data)	{
					dfd.resolve(data.response);
				},
				 error	:	function(data)	{
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		applyLocToDevice : function( devId, locId ) {
			var dfd			= new $.Deferred();
			var data 		= {id: devId, location: locId};
			var postTask	= new GVTask("/network-device/location", {
				//interval: 100, // optional.. default 500
				type : "POST"
			});
			// When task is started
			postTask.onstart	= function()  {
				// console.log("[Applying location starting]");
			};
			// When task is successful
			postTask.onsuccess	= function( status )  {
				dfd.resolve( status );
			};
			// When task fails.
			postTask.onfailure	= function( error ) {
				dfd.reject( error.response );
			};
			// Start task
			postTask.start(data);
			return dfd.promise();
		},
		deleteLocFromDevice : function( devId ) {
			var dfd			= new $.Deferred();
			var deleteTask 	= new GVTask( "/network-device/" + devId + "/location/", {
				//interval: 100, // optional.. default 500
				type : "DELETE"
			});
			// When task is started
			deleteTask.onstart	= function()  {
				// console.log("[location deletion from the device started]");
			};
			// When task is successful
			deleteTask.onsuccess = function( status )  {
				dfd.resolve( status );
			};
			// When task fails.
			deleteTask.onfailure = function( error ) {
				dfd.reject( error.response );
			};
			// Start task
			deleteTask.startDelete();
			return dfd.promise();
		},

		getNetworkDeviceByLocationId: function(locationId) {
			var dfd = new $.Deferred();
			$.ajax({
				url : window.API_URL_PREFIX + "/network-device/location/" + locationId,
				type  : "GET",
				success : function(data)  {
					dfd.resolve(data.response);
				},
					error  : function(data)  {
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},

		getTagsForADevice : function(devId) {
			var dfd = new $.Deferred();
			$.ajax({
				url : window.API_URL_PREFIX + "/tag?resourceType=network-device&resourceId=" + devId,
				type  : "GET",
				success : function(data)  {
					dfd.resolve(data.response);
				},
					error  : function(data)  {
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},

		getAllDevicesForATag: function(tagId) {
			var dfd = new $.Deferred();
			$.ajax({
				url : window.API_URL_PREFIX + "/tag/"+ tagId +"/network-device",
				type  : "GET",
				success : function(data)  {
					dfd.resolve(data.response);
				},
					error  : function(data)  {
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},

		applyTagToDevice : function(devId, tagId, fnS, fnF){
			var data 	=	{};
			var data	=	{networkDeviceId: devId, id: tagId};

			var apply_tag = new GVTask("/network-device/tag", {
				//interval: 100, // optional.. default 500
				type : "POST"
			});

			// When task is started
			apply_tag.onstart = function()  {
				// console.log("[Applying a tag to the device]");
			};
			// When task is successful
			apply_tag.onsuccess = function(status)  {
				fnS(status);
			};
			// When task fails.
			apply_tag.onfail = function(status, tree) {
				fnF(status, tree);
			};

			// Start task
			apply_tag.start(data);
		},
		deleteTagfromDevice : function(devId, tagId, fnS, fnF){
			var delete_tag = new GVTask( "/network-device/" + devId + "/tag/" + tagId, {
				//interval: 100, // optional.. default 500
				type : "DELETE"
			});

			// When task is started
			delete_tag.onstart = function()  {
				// console.log("[tag deletion started]");
			};
			// When task is successful
			delete_tag.onsuccess = function(status)  {
				fnS(status);
			};
			// When task fails.
			delete_tag.onfail = function(status, tree) {
				fnF(status, tree);
			};

			// Start task
			delete_tag.startDelete();
		},
		applyResync : function(devices)	{
			var dfd	= new $.Deferred();
			
			var reSync = new GVTask("/network-device/sync?forceSync=true", {
				//interval: 100, // optional.. default 500
				type : "PUT"
			});

			// When task is started
			reSync.onstart = function()  {
				// console.log("[Applying Resync]");
			};

			// When task is successful
			reSync.onsuccess = function( status, tree )  {
				dfd.resolve( status, tree );
			};
			// When task fails.
			reSync.onfailure = function( error ) {
				dfd.reject( error.response );
			};

			// Start task
			reSync.startPut(devices);
			return dfd.promise();
		},
		getGlobalPolltime : function() {
			var dfd = new $.Deferred();
			$.ajax({
				url : window.API_URL_PREFIX + "/network-device/collection-schedule/global",
				type  : "GET",
				success : function(data)  {
					dfd.resolve(data.response);
				},
				error  : function(data)  {
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		updatepolltime : function(data)	{
			var dfd	= new $.Deferred();
			
			var pollTime = new GVTask("/network-device/collection-schedule", {
				//interval: 100, // optional.. default 500
				type : "PUT"
			});

			// When task is started
			pollTime.onstart = function()  {
				// console.log("[Updating Polling Interval]");
			};

			// When task is successful
			pollTime.onsuccess = function( status )  {
				dfd.resolve( status );
			};
			// When task fails.
			pollTime.onfailure = function( error ) {
				dfd.reject( error.response );
			};

			// Start task
			pollTime.startPut(data);
			return dfd.promise();
		},
		getPolltime : function(devId) {
			var dfd = new $.Deferred();
			$.ajax({
				url : window.API_URL_PREFIX + "/network-device/" + devId + "/collection-schedule",
				type  : "GET",
				success : function(data)  {
					dfd.resolve(data.response);
				},
				error  : function(data)  {
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		addDevice : function(data)	{
			var dfd	= new $.Deferred();
			
			var _addDevice = new GVTask("/network-device", {
				//interval: 100, // optional.. default 500
				interval : 10000,
				type : "POST"
			});

			// When task is started
			_addDevice.onstart = function()  {
				// console.log("[Adding Device]");
			};

			// When task is successful
			_addDevice.onsuccess = function( status, tree )  {
				dfd.resolve( status, tree );
			};
			// When task fails.
			_addDevice.onfailure = function( error ) {
				dfd.reject( error.response );
			};

			// Start task
			_addDevice.start(data);
			return dfd.promise();
		},
		updateDevice : function(data)	{
			var dfd	= new $.Deferred();
			
			var _updateDevice = new GVTask("/network-device", {
				//interval: 100, // optional.. default 500
				type : "PUT"
			});

			// When task is started
			_updateDevice.onstart = function()  {
				// console.log("[Updating Device Credentials]");
			};

			// When task is successful
			_updateDevice.onsuccess = function( status, tree )  {
				dfd.resolve( status, tree );
			};
			// When task fails.
			_updateDevice.onfailure = function( error ) {
				dfd.reject( error.response );
			};

			// Start task
			_updateDevice.startPut(data);
			return dfd.promise();
		},
		getCommandOutput : function(cmdDet, fnS, fnF){
			var postTask	= new GVTask("/network-device-poller/cli/read-request", {
				//interval: 100, // optional.. default 500
				type : "POST"
			});

			// When task is started
			postTask.onstart	= function()  {
				// console.log("[Applying location starting]");
			};
			
			// When task is successful
			postTask.onsuccess	= function( status, tree )  {
				fnS( status, tree );
			};
			
			postTask.onupdate = function(res, tree)  {
				//returnTaskId(res);
				//return res;
				fnS( res , "update" );
			};
			
			// When task fails.
			postTask.onfail	= function( error ) {
				fnF( error.responseText );
			};

			// Start task
			postTask.start(cmdDet);
		},
		
		setBundlerStatus: function(name, version, enabled) {
		  var dfd = new $.Deferred();
		  var status = Number(enabled);
		  
		  var updateTask = new GVTask("/inventory/global-settings?propertyName=CLI_RUNNER_INSTALLED&propertyValue="+status, {
			  //interval: 100,
			  type: "PUT"
		  });
		  
		  updateTask.onsuccess = function(status) {
			  dfd.resolve(status);
		  };
		  
		  updateTask.onfailure = function(error) {
			  dfd.reject(error.response);
		  };
		  
		  updateTask.startPut();
		  return dfd.promise(); 
		},
		
		exportDevice : function(data)	{
			var dfd	= new $.Deferred();
			
			var _exportDevice = new GVTask("/network-device/file", {
				//interval: 100, // optional.. default 500
				type : "POST"
			});

			// When task is started
			_exportDevice.onstart = function()  {
				// console.log("[Exporting Device]");
			};

			// When task is successful
			_exportDevice.onsuccess = function( status, tree )  {
				dfd.resolve( status, tree );
			};
			// When task fails.
			_exportDevice.onfailure = function( error ) {
				dfd.reject( error.response );
			};

			// Start task
			_exportDevice.start(data);
			return dfd.promise();
		},
		
		importDevice : function(deviceFile)	{
			var dfd = new $.Deferred();

			var fd = new FormData();
			fd.append('file', deviceFile);

			var _importDevice = new GVTask("/network-device", {
			type : "FILE",
			urlPrefix : window.API_URL_PREFIX
			});

			// When task is started
			_importDevice.onstart = function()  {
			// console.log("[Uploading CSV file..]");
			};
			// When task is successful
			_importDevice.onsuccess = function(status, tree)  {
				dfd.resolve(status, tree);
			};
			// When task fails.
			_importDevice.onfail = function(error) {
				dfd.reject(error);
			};
			_importDevice.onerror = function(error) {
				dfd.reject(error);
			};

			// Start task
			_importDevice.startFile(fd);

			return dfd.promise();
		},
		
		failedDeviceReason : function(url)	{
			var dfd = new $.Deferred();
			$.ajax({
				url : window.API_URL_PREFIX + url,
				type  : "GET",
				success : function(data)  {
					dfd.resolve(data);
				},
				error  : function(data)  {
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		exportAllDevice : function(data)	{
			var dfd	= new $.Deferred();
			
			var _exportAllDevice = new GVTask("/network-device/file", {
				//interval: 100, // optional.. default 500
				type : "POST"
			});
			// When task is started
			_exportAllDevice.onstart = function()  {
				// console.log("[Exporting All Devices]");
			};
			// When task is successful
			_exportAllDevice.onsuccess = function( status, tree )  {
				dfd.resolve( status, tree );
			};
			// When task fails.
			_exportAllDevice.onfailure = function( error ) {
				dfd.reject( error.response );
			};
			// Start task
			_exportAllDevice.start(data);
			return dfd.promise();
		},
		
		getSiteByMemberId: function(memberId, indexId){
			var dfd = new $.Deferred();
			$.ajax({
				url: window.API_URL_PREFIX + '/group/member/' + memberId + '?groupType=SITE',
				type: 'GET',
				success: function(data) {
					dfd.resolve(data.response, indexId);
				},
				error: function(jqXHR, textStatus, errorThrown) {
					dfd.reject(jqXHR, indexId);
				}
			});
			return dfd.promise();
		}
	};
});