define({
    "warn_msg_browser_incmptbl": "\u60a8\u4f7f\u7528\u7684\u6d4f\u89c8\u5668\u76ee\u524d\u4e0d\u53d7\u652f\u6301\uff0c\u5e76\u4e14\u67d0\u4e9b\u529f\u80fd\u53ef\u80fd\u65e0\u6cd5\u6309\u9884\u671f\u8fd0\u884c\u3002\u8bf7\u4f7f\u7528\u6700\u65b0\u7248\u672c\u7684 Chrome \u6216 Firefox \u6d4f\u89c8\u5668\u4ee5\u786e\u4fdd\u5b8c\u5168\u517c\u5bb9\u3002",
    "warn_msg_incmpltbl_version": "\u60a8\u770b\u8d77\u6765\u4f7f\u7528\u7684\u662f {{browser}} \u7248\u672c {{version}}\u3002\u8bf7\u66f4\u65b0\u4e3a\u6700\u65b0\u7248\u672c\u7684 {{browser}} \u4ee5\u786e\u4fdd\u7ad9\u70b9\u5b8c\u5168\u517c\u5bb9\u3002",
    "label_username": "\u7528\u6237\u540d",
    "label_password": "\u5bc6\u7801",
    "label_login": "\u767b\u5f55",
    "error_msg_invalid_csrf": "CSRF \u65e0\u6548\u3002\u8bf7\u5237\u65b0\u9875\u9762\uff0c\u7136\u540e\u91cd\u8bd5\u3002",
    "error_msg_pwd_exp": "\u5BC6\u7801\u5DF2\u8FC7\u671F\u3002\u8BF7\u66F4\u6539\u5BC6\u7801",
    "error_msg_invalid_credentials": "\u767b\u5f55\u51ed\u8bc1\u65e0\u6548",
    "error_msg_unable_to_login": "\u76ee\u524d\u65e0\u6cd5\u767b\u5f55\u3002\u8bf7\u7a0d\u540e\u91cd\u8bd5\u3002",
    "error_uname_psswd_required": "\u7528\u6237\u540d\u548c\u5bc6\u7801\u662f\u5fc5\u586b\u9879",
    "msg_success": "\u6210\u529f\uff01"
});
