define({
    "thank_you_for_your_feedback": "\u611f\u8c22\u60a8\u7684\u5173\u6ce8\uff01",
    "label_feedback": "\u53cd\u9988",
    "label_email": "\u90ae\u7bb1\u5730\u5740",
    "label_make_a_suggestion": "\u63d0\u51fa\u5efa\u8bae",
    "label_cancel": "\u53d6\u6d88",
    "label_submit": "\u63d0\u4ea4",
    "sending_feedback": "\u6b63\u5728\u53d1\u9001\u53cd\u9988",
    "unable_to_submit_feedback": "\u6211\u4eec\u65e0\u6cd5\u901a\u8fc7 Cisco DNA Center \u63d0\u4ea4\u60a8\u7684\u53cd\u9988\u3002\u53ef\u80fd\u5b58\u5728\u8fde\u63a5\u95ee\u9898\u3002",
    "label_how_do_you_feel_about_dna": "\u60a8\u5bf9 Cisco DNA Center \u611f\u89c9\u5982\u4f55\uff1f",
    "label_name": "\u540d\u79f0",
    "enter_suggestion_feedback_portal": "\u5982\u679c\u613f\u610f\uff0c\u60a8\u53ef\u4ee5\u76f4\u63a5\u901a\u8fc7\u6211\u4eec\u7684\u53cd\u9988\u95e8\u6237\u8f93\u5165\u60a8\u7684\u5efa\u8bae",
    "enter_suggestion_feedback_portal_markup_string": "\u901a\u8fc7\u6211\u4eec\u7684\u53cd\u9988\u95e8\u6237",
    "cisco_may_contact_me_about_feedback": "\u601d\u79d1\u53ef\u4ee5\u5c31\u6211\u7684\u53cd\u9988\u4e0e\u6211\u8054\u7cfb",
    "make_wish": "\u6539\u8fdb\u5efa\u8bae",
    "make_wish_style":"-60px"
});
