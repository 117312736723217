define({
    "warn_msg_browser_incmptbl": "\u304a\u4f7f\u3044\u306e\u30d6\u30e9\u30a6\u30b6\u306f\u30b5\u30dd\u30fc\u30c8\u3055\u308c\u3066\u3044\u306a\u3044\u305f\u3081\u3001\u4e00\u90e8\u306e\u6a5f\u80fd\u304c\u6b63\u5e38\u306b\u6a5f\u80fd\u3057\u306a\u3044\u53ef\u80fd\u6027\u304c\u3042\u308a\u307e\u3059\u3002\u5b8c\u5168\u306a\u4e92\u63db\u6027\u3092\u78ba\u4fdd\u3059\u308b\u306b\u306f\u3001\u6700\u65b0\u30d0\u30fc\u30b8\u30e7\u30f3\u306eChrome\u307e\u305f\u306fFirefox\u3092\u4f7f\u7528\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
    "warn_msg_incmpltbl_version": "\u304a\u4f7f\u3044\u306e{{browser}}\u306e\u30d0\u30fc\u30b8\u30e7\u30f3\u306f{{version}}\u306e\u3088\u3046\u3067\u3059\u3002{{browser}}\u306e\u6700\u65b0\u30d0\u30fc\u30b8\u30e7\u30f3\u306b\u66f4\u65b0\u3057\u3001\u5b8c\u5168\u306a\u4e92\u63db\u6027\u3092\u78ba\u4fdd\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
    "label_username": "\u30e6\u30fc\u30b6\u540d",
    "label_password": "\u30d1\u30b9\u30ef\u30fc\u30c9",
    "label_login": "\u30ed\u30b0\u30a4\u30f3",
    "error_msg_invalid_csrf": "\u7121\u52b9\u306aCSRF\u3067\u3059\u3002\u30da\u30fc\u30b8\u3092\u66f4\u65b0\u3057\u3066\u304b\u3089\u3082\u3046\u4e00\u5ea6\u3084\u308a\u76f4\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
    "error_msg_pwd_exp": "\u30D1\u30B9\u30EF\u30FC\u30C9\u304C\u671F\u9650\u5207\u308C\u3067\u3059\u3002\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5909\u66F4\u3057\u3066\u304F\u3060\u3055\u3044",
    "error_msg_invalid_credentials": "\u30ed\u30b0\u30a4\u30f3\u30af\u306e\u30ec\u30c7\u30f3\u30b7\u30e3\u30eb\u304c\u7121\u52b9\u3067\u3059",
    "error_msg_unable_to_login": "\u73fe\u6642\u70b9\u3067\u30ed\u30b0\u30a4\u30f3\u3067\u304d\u307e\u305b\u3093\u3002\u6642\u9593\u3092\u304a\u3044\u3066\u518d\u8a66\u884c\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
    "error_uname_psswd_required": "\u30e6\u30fc\u30b6\u540d\u304a\u3088\u3073\u30d1\u30b9\u30ef\u30fc\u30c9\u304c\u5fc5\u8981\u3067\u3059",
    "msg_success": "\u6210\u529f\u3057\u307e\u3057\u305f"
});
