define(["jquery", "core/utils/rbac", "rest/auth"], function($, rbac, restAuth) {
  function doSignout() {
    rbac.clearCached();
    window.location = "/logout";
  }

  /**
   * Log the user out, taking them to the /login page
   */
  function _signout() {
    $.when(restAuth.signout()).then(doSignout, doSignout);
  }

  return {
    authorize: function(username, password) {
      var dfd = new $.Deferred();
      $.when(restAuth.authorize(username, password)).then(
        function(response) {
          dfd.resolve(response);
        },
        function(error) {
          dfd.reject(error);
        }
      );
      return dfd.promise();
    },
    signout: _signout
  };
});
