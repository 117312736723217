define(["jquery"], function($) {
  return {
    authorize: function(user, pass) {
      var dfd = new $.Deferred();
      $.ajax({
        type: "GET",
        url: "/api/system/v1/identitymgmt/login",
        beforeSend: function(xhr) {
          /* Authorization header */
          xhr.setRequestHeader("Authorization", "Basic " + btoa(user + ":" + pass));
        },
        success: function(response,textStatus, request) {
          localStorage.setItem("x-password-expiry-day", request.getResponseHeader("x-password-expiry-days"));
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },
    signout: function() {
      var dfd = new $.Deferred();
      $.ajax({
        type: "GET",
        contentType: "text/plain",
        url: "/api/system/v1/identitymgmt/logoff",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    }
  };
});
