define({
    "hide_package":"\ud328\ud0a4\uc9c0 \uc228\uae30\uae30",
    "show_package":"\ud328\ud0a4\uc9c0 \ubcf4\uae30",
    "fetch_package_problem":"\ud328\ud0a4\uc9c0 \ubaa9\ub85d\uc744 \uac00\uc838\uc624\ub294 \ub3d9\uc548 \ubb38\uc81c\uac00 \ubc1c\uc0dd\ud588\uc2b5\ub2c8\ub2e4.",
    "dna_center":"Cisco DNA Center",
    "appversion":"\ubc84\uc804",
    "release_note":"\ub9b4\ub9ac\uc2a4 \ub178\ud2b8",
    "copyright": "2018 Cisco Systems, Inc. All Rights Reserved."
});

