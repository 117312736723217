define(["core/plugin/kernel"], function(kernel) {
  let pluginConfigs = null;

  // Filter for subnav recusively
  function _filter(location) {
    var filteredSubNav = [];

    for (var i = 0; i < pluginConfigs.length; i++) {
      if (
        pluginConfigs[i].navigation &&
        pluginConfigs[i].navigation.location &&
        location === pluginConfigs[i].navigation.location
      ) {
        var entry = {
          title: pluginConfigs[i].title || "",
          href: pluginConfigs[i].href || "",
          subNav: pluginConfigs[i].navigation.subNav || [],
          category: pluginConfigs[i].category
        };

        entry.subNav = entry.subNav.concat(_filter(pluginConfigs[i].title));
        filteredSubNav.push(entry);
      }
    }
    return filteredSubNav;
  }

  // Get initial top level navigation using holder name, location is optional
  function _get(holder, location) {
    var filteredNav = [];
    var extendedSubNav = [];

    if (!location) {
      location = appcontext.name;
    }

    for (var i = 0; i < pluginConfigs.length; i++) {
      if (
        pluginConfigs[i].navigation &&
        pluginConfigs[i].navigation.location &&
        location === pluginConfigs[i].navigation.location &&
        holder === pluginConfigs[i].navigation.holder
      ) {
        var entry = {
          title: pluginConfigs[i].navigation.title || pluginConfigs[i].title || "",
          href: pluginConfigs[i].navigation.href || pluginConfigs[i].href || "",
          subNav: pluginConfigs[i].navigation.subNav || [],
          category: pluginConfigs[i].category
        };
        entry.subNav = entry.subNav.concat(_filter(pluginConfigs[i].title));

        filteredNav.push(entry);
      }
    }
    return filteredNav;
  }

  return {
    get: function(holder, location) {
      if (!pluginConfigs) pluginConfigs = kernel.getPluginConfigs();
      return _get(holder, location);
    }
  };
});
