define(function(require, exports, module) {
  const _ = require("underscore");
  const rbac = require("core/utils/rbac");
  const kernel = require("core/plugin/kernel");
  const qualifiedId = require("core/utils/qualifiedId");

  const apply = (logicFn, filterData, filters, internal, onError, sourcePluginId) => {
    return logicFn(filterData, (value, key) =>
      filters[key]
        ? filters[key](value, filters, internal, onError, sourcePluginId)
        : onError(key)
    );
  };

  const contribFilters = {
    // check against rbac permissions
    // example:
    //   $filter: {
    //     permissions: {
    //       "maglev-system.maglev-api.default.resource": ["gCreate"]
    //     }
    //   }
    permissions(requiredPermissions) {
      return (
        !_.isObject(requiredPermissions) ||
        _.every(
          requiredPermissions,
          (operations, resourceName) =>
            !_.isArray(operations) ||
            _.every(operations, operationName => {
              return rbac.hasPermission(resourceName, operationName);
            })
        )
      );
    },
    maintenanceMode(value, filters, internal, onError) {
      if (!internal && value !== undefined) onError("maintenanceMode");
      return (internal && value) || window.appcontext.maintenanceStatus === null;
    },
    isCloud(value) {
      const isCloud = window.appcontext.cloudProvider !== "none";
      return typeof value !== "boolean" || isCloud === value;
    },
    cloudProvider(value) {
      const values = _.flatten([value]);
      return values.some(v => v === window.appcontext.cloudProvider);
    },
    appRole(value) {
      return (
        typeof value !== "string" || window.appcontext.appRole === value.toLowerCase()
      );
    },
    pluginExists(pluginId) {
      // should be used very sparingly!
      return kernel.getContributionIndex()[pluginId];
    },
    $not(...args) {
      return apply((...fnArgs) => !_.every(...fnArgs), ...args);
    }
  };

  const internalContribFilters = Object.assign({}, contribFilters, {
    $and(...args) {
      return apply(_.every, ...args);
    },
    $or(...args) {
      return apply(_.some, ...args);
    },
    featureFlag(flagId, filters, internal, onError, sourcePluginId) {
      const featureFlags = require("core/plugin/featureFlags");
      return featureFlags.isEnabled(qualifiedId(flagId, sourcePluginId));
    },
    userIsExternalOrAdmin(value) {
      return (
        rbac.isExternalAuthSource() === value ||
        rbac.hasPermission("maglev-system.maglev-api.default.resource", "gCreate")
      );
    }
  });

  const isInternal = pluginId => pluginId.startsWith("cisco.dna.core");

  module.exports = (contribData, sourcePluginId, qualifiedExtPoint) => {
    const filterData = Object.assign(
      {maintenanceMode: undefined}, // always want a value for maint mode
      // support previous ways of specifying filters for compat purposes
      contribData.requiredPermissions
        ? {permissions: contribData.requiredPermissions}
        : {},
      contribData.$filter || {}
    );
    const internal = isInternal(sourcePluginId);
    const filters = internal ? internalContribFilters : contribFilters;
    const onError = key =>
      console.warn(
        `Unknown contribution filter "${key}" specified in ${sourcePluginId}'s contribution to ${qualifiedExtPoint}`,
        contribData
      ) || true;

    return internalContribFilters.$and(
      filterData,
      filters,
      internal,
      onError,
      sourcePluginId
    );
    // if (!ret) {
    //   console.log("FILTERING...");
    //   // if (Object.keys(filterData).filter(k => filterData[k] !== undefined).length)
    //   console.log(`${sourcePluginId}=>${qualifiedExtPoint}`, filterData, contribData);
    // }
    // return ret;
  };
});
