define({
    "hide_package":"Hide Packages",
    "show_package":"Show Packages",
    "fetch_package_problem":"There was a problem in fetching Packages List",
    "dna_center":"DNA Center",
    "appversion":"Version",
    "release_note":"Release Notes",
    "copyright": "2018 Cisco Systems Inc. All Rights Reserved."
});

