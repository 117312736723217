var Handlebars = require("/home/sdnbld/jenkins/workspace/Pipelines/apic-build/platform_ui_stable_eclipse/target/platform-ui/frontend/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<ul class='dropdown-menu dropdown-menu-right settings'>\n  <li class='_showAbout'>\n    <a><span class='_logs'>"
    + alias4(((helper = (helper = helpers.about_dna_center || (depth0 != null ? depth0.about_dna_center : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"about_dna_center","hash":{},"data":data}) : helper)))
    + "</span></a>\n  </li>\n  <li role='separator' class='divider'></li>\n  <li class='dropdown-header apic-em menu-learn'>"
    + alias4(((helper = (helper = helpers.learn || (depth0 != null ? depth0.learn : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"learn","hash":{},"data":data}) : helper)))
    + "</li>\n  <li>\n    <a href=\"https://developer.cisco.com/docs/dna-center/\" target=\"_blank\" class=\"_api documentation\">\n      <span class='nav-user-title _api-reference'>"
    + alias4(((helper = (helper = helpers.api_reference || (depth0 != null ? depth0.api_reference : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"api_reference","hash":{},"data":data}) : helper)))
    + "</span>\n      <span class='dnac-icon-newWindow-sm external'></span>\n    </a>\n  </li>\n  <li>\n    <a href=\"https://developer.cisco.com/site/dna-center\" target=\"_blank\">\n      <span class='nav-user-title _dev-resources'>\n        "
    + alias4(((helper = (helper = helpers.developer_resource || (depth0 != null ? depth0.developer_resource : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"developer_resource","hash":{},"data":data}) : helper)))
    + "\n      </span>\n      <span class='dnac-icon-newWindow-sm external'></span>\n    </a>\n  </li>\n  <li class='_helpHolder'>\n    <a>\n      <span class='nav-user-title _help'>\n        "
    + alias4(((helper = (helper = helpers.help || (depth0 != null ? depth0.help : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"help","hash":{},"data":data}) : helper)))
    + "\n      </span>\n      <span class='dnac-icon-newWindow-sm external'></span>\n    </a>\n  </li>\n  <div class='_slot help'></div>\n  <li role='separator' class='divider'></li>\n  <li>\n    <a href=\"https://mycase.cloudapps.cisco.com/case\" target=\"_blank\">\n      <span class='nav-user-title _dev-resources'>\n        "
    + alias4(((helper = (helper = helpers.contact_support || (depth0 != null ? depth0.contact_support : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contact_support","hash":{},"data":data}) : helper)))
    + "\n      </span>\n      <span class='dnac-icon-newWindow-sm external'></span>\n    </a>\n  </li>\n  <li class='_wishHolder'>\n    <a>\n      <span class='_wish'>"
    + alias4(((helper = (helper = helpers.make_a_wish || (depth0 != null ? depth0.make_a_wish : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"make_a_wish","hash":{},"data":data}) : helper)))
    + "</span>\n    </a>\n  </li>\n</ul>";
},"useData":true});