//
// Client side plugin kernel.
//
// Responsibilities:
//
// (1) Manages of "dev" plugins.  Dev plugins are remote plugins
// that are visible only on the client side. Remote plugins are
// plugins that are not bundled with the platform; they are instead
// identified by a url to their plugin manifest (i.e. ui-config.json).
//
// (2) Builds and maintains a plugin list & index for lookup of
// plugin metadata (e.g. the set of extension points & contributions).
//

define(function(require) {
  // eslint-disable-line no-undef
  const buildContributionIndex = require("core/plugin/buildContributionIndex");
  const _ = require("underscore");

  // state
  let allPluginConfigs = null;
  let contributionIndex = null;
  ////

  function ifInitialized(fn) {
    if (allPluginConfigs) {
      return fn();
    } else {
      throw new Error("Plugin kernel not yet initialized.");
    }
  }

  /**
   * @return {[Array]} Plugin configs currently loaded by the platform.
   */
  function getPluginConfigs() {
    return ifInitialized(() => allPluginConfigs);
  }

  /**
   * @return {[Object]} Contribution index
   */
  function getContributionIndex() {
    return ifInitialized(() => contributionIndex);
  }

  //
  // Returns an array of dev plugin urls from localStorage
  //
  function getDevPluginUrls() {
    var list;
    try {
      list = JSON.parse(localStorage.getItem("cisco.dna.core.devPlugins") || "[]");
    } catch (e) {
      list = [];
    }
    return list;
  }

  //
  // Sets array of dev plugin urls to localStorage
  //
  function setDevPluginUrls(urls) {
    localStorage.setItem("cisco.dna.core.devPlugins", JSON.stringify(urls || []));
    return initDevPlugins(true);
  }

  /**
   * Add dev plugin(s) associated with the given plugin manifest url.
   * @param  {[String]} url The plugin manifest url.
   * @return {[Promise]}    Promise that resolves when the operation completes.
   */
  function addDevPlugin(url) {
    const urls = getDevPluginUrls();
    if (urls.indexOf(url) === -1) {
      urls.push(url);
      return setDevPluginUrls(urls);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Remove dev plugin(s) associated with the given plugin manifest url.
   * @param  {[String]} url The plugin manifest url.
   * @return {[Promise]}    Promise that resolves when the operation completes.
   */
  function removeDevPlugin(url) {
    const urls = getDevPluginUrls();
    const idx = urls.indexOf(url);
    return idx !== -1 ? urls.splice(idx, 1) && setDevPluginUrls(urls) : Promise.resolve();
  }

  /**
   * Remove all dev plugins from local storage
   * @return {[Promise]} Resolves when the operation completes
   */
  function clearDevPlugins() {
    return setDevPluginUrls([]);
  }

  //
  // Returns the (protocol + host + port) portion of a given url
  //
  function getBaseUrl(url) {
    const parser = document.createElement("a");
    parser.href = url;
    return `${parser.protocol}//${parser.host}`;
  }

  //
  // Transform the given dev config map into a flat list configs,
  // each with a baseUrl based on the configUrl
  //
  function transformDevConfig(configUrl, devConfigMap) {
    return _.map(devConfigMap, (config, pluginId) => {
      config.pluginId = pluginId;
      config.baseUrl = config.baseUrl || getBaseUrl(configUrl);
      config.__configUrl = configUrl;
      config.__isDevPlugin = true;
      return config;
    });
  }

  // filter out plugins with duplicate ids from list.
  // plugins that appear earlier in the list win.
  // this allows dev plugins to override non-dev ones.
  function dedupe(configs) {
    const existingIds = {};
    return configs.filter(config => {
      if (!existingIds[config.pluginId]) {
        existingIds[config.pluginId] = true;
        return true;
      }
      return false;
    });
  }

  // "core" plugin definition that includes a few "baked in"
  // extension points
  const corePluginConfig = {
    pluginId: "cisco.dna.core",
    extensionPoints: {
      startup: {
        properties: {
          module: {$ref: "dna/ui/plugin#/module"}
        },
        required: ["module"]
      },
      service: {
        properties: {
          name: {type: "string"},
          requiresContext: {type: "boolean"},
          module: {$ref: "dna/ui/plugin#/module"}
        },
        required: ["name", "module"]
      },
      view: {
        properties: {
          name: {type: "string"},
          module: {$ref: "dna/ui/plugin#/module"},
          viewRef: {$ref: "dna/ui/plugin#/viewRef"}
        },
        oneOf: [{required: ["name", "module"]}, {required: ["name", "viewRef"]}]
      },
      featureFlag: {
        properties: {
          id: {type: "string"},
          category: {type: "string"},
          label: {type: "string"}
        },
        required: ["id", "label"]
      }
    }
  };

  //
  // this is the *only* function that updates state
  //
  const updateState = (function() {
    const localPluginConfigs = (window.appcontext.pluginConfigs || []).concat([]);
    return function updateState(devPluginConfigs) {
      allPluginConfigs = Object.freeze(
        dedupe(localPluginConfigs.concat(devPluginConfigs).reverse()).concat(
          corePluginConfig
        )
      );
      // combine the dev plugins with the global plugin index
      contributionIndex = Object.freeze(buildContributionIndex(allPluginConfigs));
    };
  })();

  function getDevPluginConfigs() {
    return Promise.all(
      getDevPluginUrls().map(url => {
        return fetch(url)
          .then(resp => resp.json())
          .then(devConfigMap => transformDevConfig(url, devConfigMap))
          .catch(err => console.error(`error fetching ${url}:`, err));
      })
    )
      .then(_.compact) // errors are result in undefined's; remove them from the list
      .then(_.flatten);
  }

  /**
   * Download dev plugin manifests (i.e. ui-config.json) and add them to the index.
   * Returns a Promise that resolves when the process completes.
   * @return {[Promise]} Resolves when the process completes.
   */
  function initDevPlugins(force) {
    return !force && allPluginConfigs
      ? Promise.resolve()
      : getDevPluginConfigs().then(updateState);
  }

  return {
    getPluginConfigs,
    getContributionIndex,
    addDevPlugin,
    removeDevPlugin,
    clearDevPlugins,
    init: initDevPlugins
  };
});
