var Handlebars = require("/home/sdnbld/jenkins/workspace/Pipelines/apic-build/platform_ui_stable_eclipse/target/platform-ui/frontend/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<ul class='dropdown-menu dropdown-menu-right settings'>\n	<li class='dropdown-header apic-em menu-system'>"
    + alias4(((helper = (helper = helpers.system || (depth0 != null ? depth0.system : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"system","hash":{},"data":data}) : helper)))
    + "</li>\n	<div class='_slot system'></div>\n\n	<li role='separator' class='divider'></li>\n	<li class='_usernameHolder disabled'>\n		<a>\n			<i class='dnac-icon-ham_user_sm admin-icon' />\n			<span class='username'></span>\n		</a>\n	</li>\n	<li class='_signoutHolder'>\n		<a>\n			<span class='_signout'>"
    + alias4(((helper = (helper = helpers.sign_out || (depth0 != null ? depth0.sign_out : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sign_out","hash":{},"data":data}) : helper)))
    + "</span>\n		</a>\n	</li>\n</ul>";
},"useData":true});