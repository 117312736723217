define([
  'jquery'
  ],
  function($) {
    return {
      getInstances: function() {
        var dfd = new $.Deferred();
       $.ajax({
          type: 'GET',
          url: "/serviceList",
          contentType: 'application/json',
          success: function(data) {
            dfd.resolve(data);
          },
          error: function(error){
            console.log("Failed to Retreive Instances from Grapevine");
            dfd.reject(error);
          }
        });
        return dfd.promise();
      },
      getUpdateServiceConfig: function() {
        var dfd = new $.Deferred();
        $.ajax({
          type: 'GET',
          url: window.GRAPEVINE_API_URL_PREFIX + "/release/current",
          contentType: 'application/json',
          success: function(data) {
            dfd.resolve(data);
          },
          error: function(error){
            console.log("Failed to retreive update service config from Grapevine");
            dfd.reject(error);
          }
        });
        return dfd.promise();
      }
    };
  }
);
 
