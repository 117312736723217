// Standard functions of the wrapper
define("core/utils/wrapper", [
  "./polykills",
  "jquery",
  "core/widgets/login/login",
  "core/widgets/header/navMenu",
  "core/utils/auth",
  "core/utils/DurationTrack",
  "core/apicem/utils/localStorage",
  "core/plugin/kernel",
  "core/plugin/startup",
  "core/utils/rbac",
  "core/utils/axios",
  "core/utils/extension"
], function(
  polykills,
  $,
  login,
  settingsMenu,
  auth,
  DurationTrack,
  localStorage,
  pluginKernel,
  pluginStartup,
  rbac
) {
  const AUTH_POLL_TIME_MS = 20000;


  function initLoggedIn() {
    if (!window.appcontext.authSkip) {
      scheduleCheckAuthenticated(); // start auth polling
    }
    const dfd = new $.Deferred();
    const rbacPromise = rbac.init();
    pluginKernel
      .init()
      .then(() => rbacPromise)
      .then(settingsMenu.init)
      .then(DurationTrack.init)
      .then(pluginStartup)
      .then(() => {
        dfd.resolve();
      });
    return dfd.promise();
  }

  function initLoggedOut() {
    pluginKernel.init().then(() => {
      login.init("#apicloginBox");
    });
  }

  function scheduleCheckAuthenticated() {
    setTimeout(checkAuthenticated, AUTH_POLL_TIME_MS);
  }

  /**
   * Periodically poll and check if session is still active
   * otherwise, force-sign-out the user
   */
  function checkAuthenticated() {
    $.ajax("/checkSession").then(
      scheduleCheckAuthenticated,
      (jqXHR, textStatus, errorThrown) => {
        if (jqXHR.status === 401) {
          auth.signout();
        } else {
          scheduleCheckAuthenticated();
        }
      }
    );
  }

  return {
    init: function() {
      window.RELEASE_NAME = localStorage.getItem("releaseName");
      window.RELEASE_VERSION = localStorage.getItem("releaseVersion");
      if (window.USERNAME) {
        return initLoggedIn();
      } else {
        initLoggedOut();
      }
    }
  };
});