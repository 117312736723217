/*
	Usage is like this:

	Poll(url, interval)
		.until( function(response) { condition is met })
		.then( on complete function )
		.catch( if there was an error )

 */
define([], function() {

	function Poll(url, interval=1000) {
		// polls the URL until the callback's condition is met
		// then resolves with the response
		var poller = (cb) => new Promise( (resolve, reject) => {
			var checkStatus = function() {
				$.get(url).then( response => {
					// Check condition
					if (cb(response.response)) {
						return resolve(response.response);
					}

					// otherwise, try again in {interval} time
					setTimeout(checkStatus, interval);
				}).fail(reject);
			}.bind(this);

			checkStatus();
		});

		return {
			until(cb) {
				return poller(cb);
			}
		};
	};

	return Poll;
});