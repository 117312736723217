define({
  api_reference: "API \ucc38\uc870",
  learn: "\ud559\uc2b5",
  system: "\uc2dc\uc2a4\ud15c",
  help: "\ub3c4\uc6c0\ub9d0",
  about_dna_center: "\uc815\ubcf4",
  sign_out: "\ub85c\uadf8\uc544\uc6c3",
  developer_resource: "\uac1c\ubc1c\uc790 \ub9ac\uc18c\uc2a4",
  tools: "\ub3c4\uad6c",
  make_a_wish: "\ubc14\ub77c\ub294 \uc810",
  audit_logs: "Audit Logs",
  system_settings: "System Settings"
});
