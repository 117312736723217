define([
    'jquery',
    'core/utils/GVTask'
  ],
  function($, GVTask) {
    "use strict";
    return {
      getInstances: function() {
        var dfd = new $.Deferred();
        // MAGLEV DISABLED TEMPED
/*        $.ajax({
          type: 'GET',
          url: window.GRAPEVINE_API_URL_PREFIX + '/' + "service/instances",
          contentType: 'application/json',
          success: function(data) {
            dfd.resolve(data.response);
          },
          error: function(error) {
            console.log("Failed to Retreive Instances from Grapevine");
            dfd.reject(error);
          }
        });*/
        dfd.reject();
        return dfd.promise();
      },
      getServiceInfo: function() {
        var dfd = new $.Deferred();
        $.ajax({
          type: 'GET',
          url: window.GRAPEVINE_API_URL_PREFIX + '/' + "service",
          contentType: 'application/json',
          success: function(data) {
            dfd.resolve(data.response);
          },
          error: function(error) {
            // console.log("Failed to Retreive Services from Grapevine");
            dfd.reject(error);
          }
        });
        return dfd.promise();
      },
      growInstance: function(serviceName, serviceVersion) {
        var dfd = new $.Deferred();

        var postTask = new GVTask("service/"+serviceName + "/" + serviceVersion + "/instance", {
          //interval: 100, // optional.. default 500
          type: "POST",
          urlPrefix: window.GRAPEVINE_API_URL_PREFIX
        });

        // When task is successful
        postTask.onsuccess = function(status) {
          dfd.resolve(status);
        };
        // When task fails.
        postTask.onfailure = function(error) {
          dfd.reject(error.response);
        };

        // Start task
        postTask.start();
        return dfd.promise();
      },
      harvestInstance: function(serviceName, serviceVersion) {
          var dfd = new $.Deferred();

        var deleteTask = new GVTask("service/"+serviceName + "/" + serviceVersion + "/instance", {
          //interval: 100, // optional.. default 500
          type: "DELETE",
          urlPrefix: window.GRAPEVINE_API_URL_PREFIX
        });

        // When task is successful
        deleteTask.onsuccess = function(status) {
          dfd.resolve(status);
        };
        // When task fails.
        deleteTask.onfailure = function(error) {
          dfd.reject(error.response);
        };

        // Start task
        deleteTask.startDelete();

        return dfd.promise();
      },
      getClients: function() {
        var dfd = new $.Deferred();
        $.ajax({
          type: 'GET',
          url: window.GRAPEVINE_API_URL_PREFIX + '/' + "client",
          contentType: 'application/json',
          success: function(data) {
            dfd.resolve(data.response);
          },
          error: function(error) {
            // console.log("Failed to Retreive Clients from Grapevine");
            dfd.reject(error);
          }
        });
        return dfd.promise();
      },
      getUpdateServiceConfig: function() {
        var dfd = new $.Deferred();
        $.ajax({
          type: 'GET',
          url: window.GRAPEVINE_API_URL_PREFIX + "/release/current",
          contentType: 'application/json',
          success: function(data) {
            dfd.resolve(data);
          },
          error: function(error) {
            // console.log("Failed to retreive update service config from Grapevine");
            dfd.reject(error);
          }
        });
        return dfd.promise();
      },
      getServices: function() {
        var dfd = new $.Deferred();
        $.ajax({
          url: window.GRAPEVINE_API_URL_PREFIX + "/release/latest",
          type: "GET",
          success: function(response) {
            dfd.resolve(response.response.services);
          },
          error: function(error) {
            dfd.reject(error);
          }
        });
        return dfd.promise();
      },
      getConfig: function() {
        var dfd = new $.Deferred();
        $.ajax({
          url: window.GRAPEVINE_API_URL_PREFIX + "/config",
          type: "GET",
          success: function(response) {
            dfd.resolve(response.response);
          },
          error: function(error) {
            dfd.reject(error);
          }
        });
        return dfd.promise();
      },
      getHost: function() {
        var dfd = new $.Deferred();
        $.ajax({
          url: window.GRAPEVINE_API_URL_PREFIX + "/host",
          type: "GET",
          success: function(response) {
            dfd.resolve(response.response);
          },
          error: function(error) {
            dfd.reject(error);
          }
        });
        return dfd.promise();
      },
      getHostResourceHistory: function(hostId){
        var dfd = new $.Deferred();
        $.ajax({
          // url: window.GRAPEVINE_API_URL_PREFIX + "/host/"+ hostId +"/resource/history",
          url: window.GRAPEVINE_API_URL_PREFIX + "/host/resource/statistics/one_day",
          type: "GET",
          success: function(response) {
            dfd.resolve(response.response);
          },
          error: function(error) {
            dfd.reject(error);
          }
        });
        return dfd.promise();
      },
      setProxy: function(settings) {
        var data = {
          global_config: settings
        };
        var dfd = new $.Deferred();
        $.ajax({
          url: window.GRAPEVINE_API_URL_PREFIX + "/config",
          type: "POST",
          data: JSON.stringify(data),
          contentType: 'application/json',
          success: function(data) {
            dfd.resolve(data.response);
          },
          error: function(data) {
            dfd.reject(data);
          }
        });
        return dfd.promise();
      }
    };
  }
);
