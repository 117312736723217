define([
	"jquery"
],	function($)	{
	"use strict";


	function _getDeviceCount(filter) {
		filter = filter || {
			strarr: ""
		};
		var dfd = new $.Deferred();
		$.ajax({
			url		:	window.API_URL_PREFIX +	"/network-device/count?"+filter.strarr,
			type	:	"GET",
			success	:	function(data)	{
				dfd.resolve(data.response);
			},
			 error	:	function(data)	{
				dfd.reject(data);
			}
		});
		return dfd.promise();
	}


	function _getDevices(limit, offset) {
		var dfd = new $.Deferred();
		$.ajax({
			url		:	window.API_URL_PREFIX +	"/network-device?limit=" + limit +	"&" + "offset=" + offset,
			type	:	"GET",
			success	:	function(data)	{
				dfd.resolve(data.response);
			},
			 error	:	function(data)	{
				dfd.reject(data);
			}
		});
		return dfd.promise();
	}

	return {
		getAllDevices : function(fn)	{

			var offset = 1;

			$.when(_getDeviceCount()).then(function(count) {
				var allDevices = [];
				var responseCount = 0;
				if (count > 500) {
					var todo = Math.ceil(count / 500);
					for (var i = 0; i <= todo; i++) {
						var offset = 0;
						if (i === 0) {
							offset = 1;
						}
						else {
							offset = i * 500;
						}
						$.when(_getDevices(500, offset)).then(function(response) {
							responseCount ++;
							allDevices = allDevices.concat(response);
							if (responseCount === todo + 1) {
								fn(allDevices);
							}
						},
						function(error) {
							responseCount ++;
							console.error("Error happened collecting: " + error)
							if (responseCount === todo + 1) {
								fn(allDevices);
							}
						});
					}
				}
				else {
					$.when(_getDevices(count, 1)).then(
						function(response) {
							fn(response);
						}
					);
				}
			});
		},

		getDeviceById : function(id, fn)	{
			$.ajax({
				url		:	window.API_URL_PREFIX +	"/network-device/" + id,
				type	:	"GET",
				success	:	function(data)	{
					fn(data.response);
				},
				 error	:	function(data)	{
					fn(data);
				}
			});
		},

		getDeviceByIp : function(ip)	{
			var dfd = new $.Deferred();
			$.ajax({
				url		:	window.API_URL_PREFIX +	"/network-device/ip-address/" + ip,
				type	:	"GET",
				success	:	function(data)	{
					dfd.resolve(data.response);
				},
				error	:	function(data)	{
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},

		getAllDeviceInfo : function(offset, batchsize, sortCol, sortOrder, filter)	{
			if(sortOrder === "ascending") {
				sortOrder = "asc";
			}
			else if(sortOrder === "descending") {
				sortOrder = "des";
			}
			var data  = {
				sortBy		:	sortCol || "hostname",
				order		: 	sortOrder	||	"asc",
				intoffset	:	offset,
				limit		:	batchsize
			};
			$.extend(data, filter);
			// console.log(data);
			var dfd = new $.Deferred();

			$.ajax({
				url		:	window.API_URL_PREFIX +	"/network-device/?"+ "limit=" + batchsize +	"&" + "offset=" + offset + "&"	+ "sortBy=" + sortCol + "&" + "order=" + sortOrder + "&" + filter.strarr,
				type	:	"GET",
				success	:	function(data)	{
					dfd.resolve(data.response);
				},
				 error	:	function(data)	{
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		getDeviceCount : function(filter)	{
			return _getDeviceCount(filter);
		},
		autocomplete : function(colId, term)	{
			var dfd = new $.Deferred();
			$.ajax({
				url		:	window.API_URL_PREFIX +"/network-device?autocompleteField="+colId+"&criteria="+term+"&limit=20&offset=1",
				type	:	"GET",
				success	:	function(data)	{
					dfd.resolve(data.response);
				},
				 error	:	function(data)	{
					dfd.reject(data);
				}
			});
			return dfd.promise();
		}
	};
});