var Handlebars = require("/home/sdnbld/jenkins/workspace/Pipelines/apic-build/platform_ui_stable_eclipse/target/platform-ui/frontend/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<ul class='dropdown-menu dropdown-menu-right settings'>\n	<li class='dropdown-header apic-em menu-system'>"
    + alias4(((helper = (helper = helpers.system || (depth0 != null ? depth0.system : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"system","hash":{},"data":data}) : helper)))
    + "</li>\n	<li class='dropdown-header menu-help'>"
    + alias4(((helper = (helper = helpers.help || (depth0 != null ? depth0.help : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"help","hash":{},"data":data}) : helper)))
    + "</li>\n	<li><a href=\"https://developer.cisco.com/site/dna-center-rest-api/?version=1.2.x\" target=\"_blank\" class=\"_api documentation\"><span>API Documentation</span></a> </li>\n	<li>\n		<a href=\"https://developer.cisco.com/site/dna-center\" target=\"_blank\"><span class='_dev-resources'>"
    + alias4(((helper = (helper = helpers.developer_resource || (depth0 != null ? depth0.developer_resource : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"developer_resource","hash":{},"data":data}) : helper)))
    + "</span></a>\n	</li>\n	<li class='_showAbout'>\n		<a><span class='_logs'>"
    + alias4(((helper = (helper = helpers.about_dna_center || (depth0 != null ? depth0.about_dna_center : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"about_dna_center","hash":{},"data":data}) : helper)))
    + "</span></a>\n	</li>\n	<!--<li class='dropdown-header menu-user'>User</li>\n	<li class='_changePasswordHolder'>\n		<a href=\"/dna/systemSettings/settings?settings-item=changePassword\">\n			<span class='_changePassword'>Change Password</span>\n		</a>\n	</li>-->\n	<li role='separator' class='divider'></li>\n	<li class='username'></li>\n	<li class='_signoutHolder'>\n		<a>\n			<span class='_signout'>"
    + alias4(((helper = (helper = helpers.sign_out || (depth0 != null ? depth0.sign_out : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sign_out","hash":{},"data":data}) : helper)))
    + "</span>\n		</a>\n	</li>\n</ul>";
},"useData":true});