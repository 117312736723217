define([
  "jquery",
  "core/utils/auth",
  "apic-em-widgets/widgets/Informer",
  "apicem-widgets/widgets/form/textField/TextField",
  "apicem-widgets/widgets/form/button/Button",
  "core/apicem/utils/cookie",
  "core/widgets/login/releaseLoader",
  "core/utils/SupportedBrowser",
  "core/utils/serviceRegistry",
  "text!core/widgets/login/login.html",
  "i18n!./nls/i18n_login",
  "core/utils/i18n",
  "./less/login.less"
], function(
  $,
  auth,
  Informer,
  TextField,
  Button,
  cookie,
  releaseLoader,
  SupportedBrowser,
  serviceRegistry,
  template,
  nls,
  i18nUtil
) {
  const {isSupportedBrowser, isSupportedVersion, getBrowserInfo} = SupportedBrowser;
  let loginButton;
  let usernameField;
  let passwordField;
  let info;
  let templateHolder;

  // var NUM_IMAGES = 4;
  let img_id = 1;
  const IMAGES = require.context("./img");
  const GetImgHigh = function(id) {
    return IMAGES(`./HiRes_Image_${id}.jpg`);
  };
  const GetImgLow = function(id) {
    return IMAGES(`./LowRes_Image_${id}.jpg`);
  };
  const RandomBackgroundImage = function() {
    return Math.floor(Math.random() * (IMAGES.keys().length / 2) + 1); // +1 because image index starts at 1 instead of 0
  };

  async function createTemplateHolder() {
    const branding = await serviceRegistry.getService(
      "cisco.dna.core.product",
      "branding"
    );
    const warn_msg_incmpltbl_version = i18nUtil.getFilledTextForHBS(
      nls.warn_msg_incmpltbl_version,
      getBrowserInfo()
    );
    const templ = i18nUtil.getFilledTextForHBS(
      template,
      Object.assign({}, nls, branding, {
        warn_msg_incmpltbl_version
      })
    );
    return $(templ);
  }

  function _showSuccess() {
    templateHolder
      .find("._form-error")
      .removeClass("alert-danger")
      .addClass("alert-success")
      .removeClass("hide")
      .text(nls.msg_success);
  }

  function _showError(msg) {
    templateHolder
      .find("._form-error")
      .removeClass("hide")
      .text(msg);
  }

  function _clearError() {
    templateHolder
      .find("._form-error")
      .addClass("hide")
      .text("");
  }

  function _loadHQBackground() {
    // Load the new image into memory to get the cache going,
    // then set the login box background to the high version
    var newBackgroundImage = new Image();
    var hiResImgPath = GetImgHigh(img_id);

    newBackgroundImage.onload = function() {
      $("#apicloginBoxHolder").css("background-image", "url('" + hiResImgPath + "')");
    };
    newBackgroundImage.src = hiResImgPath;
  }

  function _loginSuccess(response) {
    // Initiate the auth module using the set credentials that auth has globalized
    localStorage.setItem("csrf", "temp-csrf-token"); // TEMP: Update to response.csrf once Maglev supports
    const success = () => {
      _showSuccess();
      window.location.reload();
    };
    releaseLoader.setReleaseInfo().then(success, success);
  }

  function _loginFail(error) {
    var msg = "";

    if (error.status === 403 && error.responseText === "Password has expired. Please change the password") {
      _showError(nls.error_msg_pwd_exp);
      loginButton.setText(nls.label_login);
      return;
    }
    if (error.status === 403) {
      _showError(nls.error_msg_invalid_csrf);
      loginButton.setText(nls.label_login);
      return;
    }

    if (error.status === 429) {
      _showError(error.responseText);
      loginButton.setText(nls.label_login);
      loginButton.enabled(true);
      usernameField.enabled(true);
      passwordField.enabled(true);
      passwordField.setVal("");
      return;
    }

    var responseBody = error.responseText;
    if (error.status === 401) {
      msg = nls.error_msg_invalid_credentials;
    } else if (
      error.status === 503 ||
      error.status === 501 ||
      error.status === 500 ||
      error.status === 400 ||
      error.status === 404
    ) {
      msg = nls.error_msg_unable_to_login;
    } else if (responseBody.response && responseBody.response.message) {
      msg = responseBody.response.message;
    } else {
      msg = error.status + ": " + error.statusText;
    }
    _showError(msg);
    templateHolder.find("._informer div").css({
      color: "#FFF",
      border: "0.1rem solid #EC2300",
      background: "#323232"
    });
    loginButton.setText(nls.label_login);
    loginButton.enabled(true);
    usernameField.enabled(true);
    passwordField.enabled(true);
    passwordField.setVal("");
  }

  function _performLogin() {
    loginButton.enabled(false);
    usernameField.enabled(false);
    passwordField.enabled(false);
    _clearError();

    var user = (usernameField.getVal() || "").toLowerCase();
    var pass = passwordField.getVal();

    loginButton.setText("<i class='fa fa-refresh fa-spin'></i>");

    if (!user || !pass) {
      _showError(nls.error_uname_psswd_required);
      templateHolder.find("._informer div").css({
        color: "#FFF",
        border: "0.1rem solid #EC2300",
        background: "#323232"
      });
      loginButton.enabled(true);
      usernameField.enabled(true);
      passwordField.enabled(true);
      loginButton.setText(nls.label_login);
    } else {
      $.when(auth.authorize(user, pass)).then(
        function(response) {
          _loginSuccess(response);
        },
        function(error) {
          _loginFail(error);
        }
      );
    }
  }

  async function _init(applyToDom) {
    // Set background image to random image
    img_id = RandomBackgroundImage();
    var lowResImgPath = GetImgLow(img_id);
    $("body").css("background", "url('" + lowResImgPath + "')");
    templateHolder = await createTemplateHolder();

    if (!applyToDom) {
      $("body").append(templateHolder);
    } else {
      $(applyToDom).append(templateHolder);
    }
    $("#_apicPageMain").css("visibility", "visible");

    cookie.removeItem("idleTimeoutExpiresAt");

    templateHolder.find("._informer").empty();
    templateHolder.find("._login").empty();
    templateHolder.find("._user").empty();
    templateHolder.find("._pass").empty();

    info = new Informer(templateHolder.find("._informer"));
    loginButton = new Button(null, {
      setText: nls.label_login,
      theme: "btn btn-primary"
    });
    usernameField = new TextField(null, {
      label: nls.label_username,
      required: true
    });
    usernameField.regex(/^.{1,255}$/, "You have exceeded the maximum character limit");
    passwordField = new TextField(null, {
      label: nls.label_password,
      password: true,
      required: true
    });
    passwordField.regex(/^.{1,255}$/, "You have exceeded the maximum character limit");

    templateHolder.find("._user").html(usernameField.domNode);
    templateHolder.find("._pass").html(passwordField.domNode);
    templateHolder.find("._login").html(loginButton.domNode);
    templateHolder.find("._user ._txt").css({
      "margin-left": "-6px"
    });
    templateHolder.find("._pass ._txt").css({
      "margin-left": "-6px"
    });

    $("._user").keyup(function(e) {
      if (e.keyCode === 13) {
        _performLogin();
      }
    });
    $("._pass").keyup(function(e) {
      if (e.keyCode === 13) {
        _performLogin();
      }
    });

    loginButton.click(function() {
      _performLogin();
    });

    // If recentled auto thrown out then display Token no longer valid
    if (cookie.getItem("invalidServiceTicketLogout")) {
      console.warn(cookie.getItem("invalidServiceTicketLogout"));
      info.display("ERROR", cookie.getItem("invalidServiceTicketLogout"));
      templateHolder.find("._informer div").css({
        color: "#FFF",
        border: "0.1rem solid #EC2300",
        background: "#323232"
      });
    }

    if (!isSupportedBrowser()) {
      $(".supported-error").removeClass("hide");
      $(".supported-browser-warning").removeClass("hide");
    } else if (!isSupportedVersion()) {
      $(".supported-error").removeClass("hide");
      $(".supported-version-warning").removeClass("hide");
    }

    // Load the high quality BG once done initializing
    //  on the next render
    setTimeout(function() {
      _loadHQBackground();
    }, 0);
  }

  return {
    init: async function(applyToDom) {
      _init(applyToDom);
    },
    hideBorder: function() {
      templateHolder.find(".dialog").css({
        border: "none",
        boxShadow: "none"
      });
    }
  };
});