define({
  "root":{
    "warn_msg_browser_incmptbl": "The browser you are using is currently unsupported and some features may not work as expected. Please use the latest version of Chrome or Firefox to ensure full compatibility.",
    "warn_msg_incmpltbl_version": "It looks like you're on {{browser}} version {{version}}. Please update to the latest version of {{browser}} to ensure full site compatibility.",
    "label_username": "Username",
    "label_password": "Password",
    "label_login": "Log In",
    "error_msg_invalid_csrf": "Invalid CSRF. Please refresh page and try again.",
    "error_msg_pwd_exp": "Password has expired. Please change the password",
    "error_msg_invalid_credentials": "Invalid Login Credentials",
    "error_msg_unable_to_login": "Unable to log in at this moment. Please try again at a later time.",
    "error_uname_psswd_required": "Username & Password required",
    "msg_success": "Success!"
  },
  "en": true,
  "ja":true,
  "zh": true,
  "ko":true
});
