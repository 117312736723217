/***************************************************

   Release based loader

   Upon login complete, it gets the release info from
   GV API, and set dynamically through POST API.
   Default release : default
   Default apps : All

****************************************************/

define(["jquery", "core/apicem/utils/localStorage", "rest/release"], function(
  $,
  localStorage,
  restRelease
) {
  //
  // get release info and save it to localStorage for later
  // reference, after page reloads
  //
  function setReleaseInfo() {
    var dfd = new $.Deferred();
    $.when(restRelease.getCurrentRelease()).then(
      function(releaseVersion) {
        localStorage.setItem("releaseVersion", releaseVersion);
        dfd.resolve();
      },
      function() {
        dfd.reject();
      }
    );
    return dfd.promise();
  }

  return {
    setReleaseInfo
  };
});
