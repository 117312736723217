define([
  "i18n!./nls/i18_maintenanceCheck",
  "./constants.js"
],
  function (
    i18nMessageBundle,
    constants
  ) {
    const root = document.getElementById("root"),
      progressEl = document.getElementById("progress"),
      progressBarEl = document.getElementById("progress-bar"),
      percentComplete = document.getElementById("percent-complete"),
      currentItem = document.getElementById("current-item"),
      tasksList = document.getElementById("tasks-list"),
      systemUpdateEl = document.getElementById("system-update-details"),
      errorWarning = document.getElementById("error-warning");

    let _isAlternativeApi = false;
    let _requestIsPending = false;

    let _tasksListIsShown = false;
    let _actionsWereSet = false;

    let _systemUpdateApiResponded = false;

    const _globalTimeInterval = 15000;
    const _globalTimeout = 10000;

    let _globalPoller;

    const resetBrowser = location => (window.location.href = location);

    const errorTimer = {
      timer: null,
      init() {
        this.timer = setTimeout(() => {
          errorWarning.style.display = "block";
        }, 900000);
      },
      reset() {
        clearTimeout(this.timer);
        this.init();
      }
    };
    errorTimer.init();

    const _switchToAlternativeApi = obj => {
      if ((!_isAlternativeApi && obj.isUpdateStatusApi) ||
        (_isAlternativeApi && obj.isAlternativeUpdateStatusApi)) {
        _isAlternativeApi = !_isAlternativeApi;
      }
    };

    const request = obj =>
      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", obj.url);
        xhr.timeout = _globalTimeout;

        xhr.onload = () => {
          _requestIsPending = false;
          if (xhr.status === 200) {
            errorTimer.reset();
            try {
              resolve(JSON.parse(xhr.response));
            } catch (e) {
              restart();
            }
          } else {
            _switchToAlternativeApi(obj);
            restart();
          }
        };

        xhr.onerror = () => {
          _requestIsPending = false;
          _switchToAlternativeApi(obj);
          restart();
        };

        xhr.ontimeout = () => {
          _requestIsPending = false;
          _switchToAlternativeApi(obj);
          restart();
        };

        xhr.send(obj.body);
        _requestIsPending = true;
      });

    const unAuthenticate = () => request({ url: "/api/system/v1/identitymgmt/logoff" });

    const getRestoreStatus = () => request({ url: "/api/system/v1/maglev/restore/progress" });

    const getUpdateStatus = () => request({
        url: "/api/system/v1/systemupdater/common/update_status",
        isUpdateStatusApi: true
      });

    const getAlternativeUpdateStatus = () => {
      const mainUrlPart = `${window.location.protocol}//${window.location.hostname}`;
      request({
        url: `${mainUrlPart}:26375/api/system/v1/system_updater_standby/common/update_status`,
        isAlternativeUpdateStatusApi: true
      });
    }

    const getMainCheckOfMainModeStatus = () => request({
        url: "/api/system/v1/maglev/maintenance/status"
      });

    const _getTakenTime = takenTime => {
      let unit = 'Second(s)';
      let result = takenTime;
      if (takenTime > 60) {
        result = Math.round(takenTime / 60);
        unit = 'Minute(s)';
      }
      return `${result} ${unit}`;
    };

    const _getTasksListTemplate = tasks => {
      let tasksList = '';

      if (tasks && tasks.length > 0) {
        const successedTasks = [];
        const pendingTasks = [];

        tasks.forEach(task => {
          if (task.status === 'SUCCESS' || task.status === 'FAILED') {
            successedTasks.push(task);
          } else {
            pendingTasks.push(task);
          }
        });

        pendingTasks.concat(successedTasks).forEach(task => {
          tasksList = `
          ${tasksList}
          <div class="task-row">
            <div class="name">
              ${constants.backupRestoreTasks[task.task_name] || task.task_name}
            </div>
            <div class="status">${task.status}</div>
            <div class="time-left">
              ${
            task.end_timestamp && task.start_timestamp
              ? `Took ${
              _getTakenTime(Math.round(task.end_timestamp - task.start_timestamp))
              }`
              : '...'
            } 
            </div>
          </div>
        `;
        });
      }

      return tasksList;
    };

    const showRestoreProgress = data => {
      const completed = data.filter(task => task.status === "SUCCESS").length,
        progress = ((completed / data.length) * 100).toString().substr(0, 2);

      progressBarEl.classList.add("update-progress");
      percentComplete.style.width = `${progress}%`;
      progressEl.innerHTML = `${progress}% complete`;

      currentItem.innerHTML = `
        ${completed} of ${data.length} tasks completed.
        <span id="show-tasks-details">
          ${_tasksListIsShown ? 'Hide' : 'Show'} details.
        </span>
      `;
      tasksList.innerHTML = _getTasksListTemplate(data);
    };

    const _getSystemUpdateProcessDetails = data => `
      <div class="details-block">
        <div class="block">
          <div class="current-title">
            Phase:
          </div>
          <div class="current-value">
            ${data.currentPhaseDetails || data.updaterStatusMessage}
          </div>
        </div>

        <div class="system-update-progress-message">
          Please do not refresh your browser or click your browser's back button. When the upgrade is complete, you will be redirected to Cisco DNA Center.
        </div>
      </div>
    `;

    const showUpdateStatus = systemUpdateObj => {
      progressBarEl.classList.add("update-progress");
      currentItem.innerHTML = `${systemUpdateObj.updateProgressPercent}% complete`;
      percentComplete.style.width = `${systemUpdateObj.updateProgressPercent}%`;

      systemUpdateEl.innerHTML = _getSystemUpdateProcessDetails(systemUpdateObj);
    };

    const checkAuth = res => {
      return new Promise((resolve, reject) => {
        if (
          res.message === "Unauthorized" ||
          res.message === "Invalid signature" ||
          res.error
        ) {
          reject();
        } else {
          let result = res.response.errorCode;
          if (
            res.response.maintenance !== "false" &&
            !result &&
            (!res.response.installedVersion ||
              (res.response.installedVersion && res.response.installedVersion.length === 0))
          ) {
            result = "MAINTENANCE_SYSTEM_UPDATE_IN_PROGRESS";
          }
          resolve(result);
        }
      });
    };

    const whichErr = res => {
      const errorCodes = {
        MAINTENANCE_IN_PROGRESS_ERROR: {
          poll: getMainCheckOfMainModeStatus,
          text: i18nMessageBundle.maintenance_in_progress,
          zcase: s => s.response.maintenance === "false",
          redirectUrl: "/"
        },
        MAINTENANCE_RESTORE_IN_PROGRESS: {
          poll: getRestoreStatus,
          text: i18nMessageBundle.restoring_backup,
          zcase: s => {
            if (s.response && s.response.length) {
              showRestoreProgress(s.response[0].tasks);
              return s.response[0].status === "SUCCESS";
            }
            return true;
          },
          redirectUrl:
            "/dna/systemSettings/backup?st-systemSettings=backup&st-backupsTabContainer=backups"
        },
        MAINTENANCE_SCALE_IN_PROGRESS: {
          poll: getMainCheckOfMainModeStatus,
          text: i18nMessageBundle.system_scale_in_progress,
          zcase: s => s.response.maintenance === "false",
          redirectUrl: "/dna/systemSettings/system360"
        },
        MAINTENANCE_SYSTEM_UPDATE_IN_PROGRESS: {
          poll: !_isAlternativeApi ? getUpdateStatus : getAlternativeUpdateStatus,
          text: _systemUpdateApiResponded ?
            i18nMessageBundle.system_update_in_progress : i18nMessageBundle.checking_system_update,
          isSystemUpdateProcess: true,
          zcase: s => {
            _systemUpdateApiResponded = true;
            if (s.response) {
              const { updateProgressPercent, updaterState } = s.response;
              if (updateProgressPercent !== "IDLE") {
                showUpdateStatus(s.response);
              }
              return (
                updateProgressPercent === 100 ||
                updaterState === "IDLE" ||
                updaterState === "FAILED"
              );
            }
            return true;
          },
          redirectUrl: "/dna/systemSettings?st-systemSettings=applicationManagement"
        },
        INVALID_REQUEST_ERROR: {
          poll: getMainCheckOfMainModeStatus,
          text: i18nMessageBundle.checking_maintenance_mode,
          zcase: s => s.response.maintenance === "false",
          redirectUrl: "/"
        }
      };
      return Promise.resolve(
        errorCodes[res ? res : "MAINTENANCE_IN_PROGRESS_ERROR"] ||
        errorCodes.MAINTENANCE_IN_PROGRESS_ERROR
      );
    };

    const setErrText = err => {
      root.innerHTML = err.text;
      return Promise.resolve(err);
    };

    const startPolling = err => {
      _globalPoller = setInterval(function pollRequest() {
        if (!_requestIsPending) {
          const promise = err.poll();
          if (promise) {
            promise.then(
              s => {
                if (s.message === "Unauthorized" || s.message === "Invalid role id" || s.error) {
                  getMainCheckOfMainModeStatus().then(status => {
                    if (status.response && status.response.maintenance === "false") {
                      unAuthenticate().then(() => resetBrowser(err.redirectUrl));
                    }
                  });
                } else if (err.zcase(s)) {
                  getMainCheckOfMainModeStatus().then(() => resetBrowser(err.redirectUrl));
                }
              });
          }
        }
        
        return pollRequest;
      }(), _globalTimeInterval);
    };

    const _initActions = () => {
      if (!_actionsWereSet) {
        $(document).on('click', '#show-tasks-details', function () {
          if (!_tasksListIsShown) {
            $(this).html('Hide details...');
            $('#tasks-list').removeClass('hidden');
          } else {
            $(this).html('Show details...');
            $('#tasks-list').addClass('hidden');
          }
          _tasksListIsShown = !_tasksListIsShown;
        });

        _actionsWereSet = true;
      }
    };

    /**
     * Check the maintenance api to get an error code which tells who is the initiator
     * of maintenance mode. If code is obtained, start polling an api which provides
     * a more detailed status.
     * Any errors caught will restart the checks until maintenance api returns status === false.
     */
    const _initChecks = () => {
      _initActions();
      return getMainCheckOfMainModeStatus()
        .then(checkAuth)
        .then(whichErr)
        .then(setErrText)
        .then(startPolling)
        .catch(() => restart());
    };

    const restart = () => {
      if (_globalPoller) {
        clearInterval(_globalPoller);
        _globalPoller = undefined;
      }

      setTimeout(() => _initChecks(), _globalTimeInterval);
    };

    return _initChecks;
  });