define([
  'jquery'
], function($) {
	"use strict";
	return { 
	getDeviceConfig : function(deviceId) {
		var dfd = new $.Deferred();
		$.ajax({
			url : window.API_URL_PREFIX +  "/network-device/" + deviceId + "/config",
			type  : "GET",
			success : function(data)  {
				dfd.resolve(data.response);
			},
			error  : function(error)  {
				if (error.responseJSON) {
					dfd.reject(error.responseJSON.response);
				}
				else {
					dfd.reject(error);
				}
		 	}
		});
		return dfd.promise();
	}
  }
});