var amdi18n={"__root":{"digits_only":"Use digits only","digits_and_spaces_only":"Use digits and spaces only","digits_letters_only":"Use digits and letters only"},"__en":{"use_digits_only":"Use digits only","use_digits_and_spaces_only":"Use digits and spaces only","use_digits_letters_only":"Use digits and letters only"},"__ja":{"digits_letters_only":"数字と文字のみを使用","digits_only":"数字のみを使用","digits_and_spaces_only":"数字とスペースのみを使用"},"__zh":{"digits_letters_only":"仅使用数字和字母","digits_only":"仅使用数字","digits_and_spaces_only":"仅使用数字和空格"},"__ko":{"digits_letters_only":"숫자와 문자만 사용","digits_only":"숫자만 사용","digits_and_spaces_only":"숫자와 공백만 사용"}};amdi18n.init=function (language){
		// get the default language
		if(!language){
			if(window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(document.documentElement.lang){
				language = document.documentElement.lang;
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		for(var name in this.__root){
			if(typeof this[name] === 'undefined'){
				this[name] = this.__root[name];
			}
		}
	};amdi18n.init();module.exports=amdi18n;