define([
  'jquery',
  "core/utils/GVTask"
], function($, GVTask) {
	"use strict";
	return { 
	updateDeviceRole : function(deviceId, role) {
		var dfd = new $.Deferred();
		var data = {id: deviceId, role: role, roleSource:"MANUAL"};

	    var dfd = new $.Deferred();
	    var addPolicy = new GVTask("/network-device/brief", {
	    	type: "PUT"
	    });
	    addPolicy.onsuccess = function(status)  {
	    	dfd.resolve(status);
	    };
	    addPolicy.onfail = function(status, tree) {
	    	dfd.reject(status);
	    };
	    addPolicy.onerror = function(status, tree) {
	    	dfd.reject(status);
	    };
	    addPolicy.startPut(data);
	    return dfd.promise();
	}
  }
});