define([
  "jquery",
  "apic-em-widgets/widgets/loader/Loader",
  "text!core/utils/aboutDnac.html",
  "underscore",
  "../../core/dnacData"
], function ($, Loader, aboutDnacTemplate, _, dnacDataObj) {
  let resultTemplate = null;
  let packagesLoader = null;
  let serialsLoader = null;
  let packagesFlag = false;
  let serialsFlag = false;

  const _sortByAboutPackageName = data => {
    const result = Object.keys(data).map(key => data[key]);

    result.sort((elem1, elem2) => {
      const elem1NameToLowerCase = (elem1.displayName || elem1.name).toLowerCase();
      const elem2NameToLowerCase = (elem2.displayName || elem2.name).toLowerCase();

      if (elem1NameToLowerCase > elem2NameToLowerCase) {
        return -1;
      }
      if (elem1NameToLowerCase < elem2NameToLowerCase) {
        return 1;
      }
      return 0;
    });

    return result;
  };

  const _parseSerialNumbers = data => {
    const {nodes = []} = data;
    const serialArray = [];
    for (const node of nodes) {
      const {platform: {serial = ""} = {}} = node;
      if (serial) serialArray.push(serial);
    }
    return serialArray;
  }

  const _slidenShowPackages = () => {
    $(".allVersions").slideDown("slow");
    $(".show-packages-title").html("Packages");
    $(".show-packages-icon").removeClass("dnac-icon-next").addClass("dnac-icon-dropdown-site");
  };

  const _stopLoadernSlideDownPackages = () => {
    $(".show-packages-title").removeClass("loaderSpace");
    packagesLoader.done();
    packagesLoader = null;
    _slidenShowPackages();
  };

  const _showorHidePackges = () => {
    if (packagesFlag === false) {
      if ($(".allVersions span:first-child").siblings().length === 0) {
        packagesLoader = Loader.start("Fetching Packages", $(".show-packages-title"));
        $(".show-packages-title").addClass("loaderSpace");
      } else {
        _slidenShowPackages();
      }
      packagesFlag = true;
    } else if (packagesFlag === true) {
      $(".allVersions").slideUp("slow");
      $(".show-packages-title").html("Packages");
      $(".show-packages-icon").addClass("dnac-icon-next").removeClass("dnac-icon-dropdown-site");
      packagesFlag = false;
    }
  };

  const _slidenShowSerials = () => {
    $(".allSerialNumbers").slideDown("slow");
    $(".show-serial-numbers-title").html("Serial number");
    $(".show-serial-numbers-icon").removeClass("dnac-icon-next").addClass("dnac-icon-dropdown-site");
  };

  const _stopSerialsLoadernSlideDown = () => {
    $(".show-serial-numbers-title").removeClass("loaderSpace");
    serialsLoader.done();
    serialsLoader = null;
    _slidenShowSerials();
  };

  const _showorHideSerials = () => {
    if (serialsFlag === false) {
      if ($(".allSerialNumbers span:first-child").siblings().length === 0) {
        serialsLoader = Loader.start("Fetching Serial Number", $(".show-serial-numbers-title"));
        $(".show-serial-numbers-title").addClass("loaderSpace");
      } else {
        _slidenShowSerials();
      }
      serialsFlag = true;
    } else if (serialsFlag === true) {
      $(".allSerialNumbers").slideUp("slow");
      $(".show-serial-numbers-title").html("Serial number");
      $(".show-serial-numbers-icon").addClass("dnac-icon-next").removeClass("dnac-icon-dropdown-site");
      serialsFlag = false;
    }
  };

  const _init = () => {
    const compiledTemplate = _.template(aboutDnacTemplate);
    resultTemplate = $(compiledTemplate(dnacDataObj));

    const $modal = $("<dnx-modal>");
    $modal.append(resultTemplate);

    $modal.prop({ width: "530px" })

    $("body").append($modal)
    $modal.on("close", () => {
      $modal.remove();
    });

    $(".show-packages-block").click(() => {
      _showorHidePackges();
    });

    if ($(".allVersions span:first-child").siblings().length > 0) {
      $(".allVersions").html("<span class=\"keypairs\"></span>");
    }

    $(".show-serial-numbers-block").click(() => {
      _showorHideSerials();
    });

    if ($(".allSerialNumbers span:first-child").siblings().length > 0) {
      $(".allSerialNumbers").html("<span class=\"numbers\"></span>");
    }

    $.ajax({
      type: "GET",
      url: "/api/system/v1/maglev/packages",
      success: data => {
        const result = _sortByAboutPackageName(data.response);
        result.forEach(item => {
          $(".keypairs").after(`
            <div class="labelContainer">
              <span class="appNameLabel">
                ${item.displayName || item.name}
              </span>
              <span class="appVersion">
                ${item.version}
              </span>
            </div>
          `);
        });
        if (packagesLoader) {
          _stopLoadernSlideDownPackages();
        }
      },
      error: () => {
        $(".keypairs").after("<div>There was a problem in fetching Packages List</div>");
        if (packagesLoader) {
          _stopLoadernSlideDownPackages();
        }
      }
    });

    $.ajax({
      type: "GET",
      url: "/api/system/v1/maglev/nodes/config",
      success: data => {
        const serialArray = _parseSerialNumbers(data.response);
        if (serialArray.length) {
          serialArray.forEach(item => {
            $(".numbers").after(`
              <div class="serialNumberContainer">
                <span class="serialNumberLabel">
                  ${item}
                </span>
              </div>
            `);
          });
        } else {
          $(".numbers").after("<div>There was a problem in fetching Serial Numbers List</div>");
        }
        if (serialsLoader) {
          _stopSerialsLoadernSlideDown();
        }
      },
      error: () => {
        $(".numbers").after("<div>There was a problem in fetching Serial Numbers List</div>");
        if (serialsLoader) {
          _stopSerialsLoadernSlideDown();
        }
      }
    });
  };

  return {
    init() {
      _init();
    }
  };
});
