define([
  "amcharts",
  "amcharts.serial",
  "amcharts.themes.light",
  "amcharts.pie",
  "amcharts.responsive",
  "amcharts.export",
  "amcharts.xy",
  "dnx-web-components/dist/index-with-deps.min"
], function(amcharts, serial, light, pie, responsive, expt, xy, a) {
  return a;
});
