define({
    "warn_msg_browser_incmptbl": "\uc0ac\uc6a9 \uc911\uc778 \ube0c\ub77c\uc6b0\uc800\ub294 \ud604\uc7ac \uc9c0\uc6d0\ub418\uc9c0 \uc54a\uc73c\uba70, \uc77c\ubd80 \uae30\ub2a5\uc774 \uc608\uc0c1\ub300\ub85c \uc791\ub3d9\ud558\uc9c0 \uc54a\uc744 \uc218 \uc788\uc2b5\ub2c8\ub2e4. \uc804\uccb4 \ud638\ud658\uc131\uc744 \ubcf4\uc7a5\ud558\ub824\uba74 Chrome \ub610\ub294 Firefox\uc758 \ucd5c\uc2e0 \ubc84\uc804\uc744 \uc0ac\uc6a9\ud558\uc2ed\uc2dc\uc624.",
    "warn_msg_incmpltbl_version": "{{browser}} {{version}} \ubc84\uc804\uc744 \uc0ac\uc6a9\ud558\uace0 \uacc4\uc2e0 \uac83 \uac19\uc2b5\ub2c8\ub2e4. \uc804\uccb4 \uc0ac\uc774\ud2b8 \ud638\ud658\uc131\uc744 \ubcf4\uc7a5\ud558\ub824\uba74 {{browser}}\uc758 \ucd5c\uc2e0 \ubc84\uc804\uc73c\ub85c \uc5c5\ub370\uc774\ud2b8\ud558\uc2ed\uc2dc\uc624.",
    "label_username": "\uc0ac\uc6a9\uc790 \uc774\ub984",
    "label_password": "\ube44\ubc00\ubc88\ud638",
    "label_login": "\ub85c\uadf8\uc778",
    "error_msg_invalid_csrf": "\uc798\ubabb\ub41c CSRF\uc785\ub2c8\ub2e4. \ud398\uc774\uc9c0\ub97c \uc0c8\ub85c \uace0\uce58\uace0 \ub2e4\uc2dc \uc2dc\ub3c4\ud558\uc2ed\uc2dc\uc624.",
    "error_msg_pwd_exp": "\uC554\uD638\uAC00 \uB9CC\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uBE44\uBC00\uBC88\uD638\uB97C \uBCC0\uACBD\uD558\uC2ED\uC2DC\uC624.",
    "error_msg_invalid_credentials": "\uc798\ubabb\ub41c \ub85c\uadf8\uc778 \uc790\uaca9 \uc99d\uba85",
    "error_msg_unable_to_login": "\uc9c0\uae08\uc740 \ub85c\uadf8\uc778\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4. \ub098\uc911\uc5d0 \ub2e4\uc2dc \uc2dc\ub3c4\ud558\uc2ed\uc2dc\uc624.",
    "error_uname_psswd_required": "\uc0ac\uc6a9\uc790 \uc774\ub984 \ubc0f \ube44\ubc00\ubc88\ud638 \ud544\uc694",
    "msg_success": "\uc131\uacf5"
});
