var amdi18n={"__root":{"maintenance_in_progress":"Maintenance in progress","restoring_backup":"Restoring backup","system_scale_in_progress":"System scale in progress","system_update_in_progress":"System update in progress","checking_system_update":"Checking system update status...","checking_maintenance_mode":"Checking maintenance mode status..."},"__en":{"maintenance_in_progress":"Maintenance in progress","restoring_backup":"Restoring backup","system_scale_in_progress":"System scale in progress","system_update_in_progress":"System update in progress","checking_maintenance_mode":"Checking maintenance mode status..."},"__ja":{"maintenance_in_progress":"メンテナンスが進行中","restoring_backup":"バックアップの復元中","system_scale_in_progress":"システムスケールが進行中","system_update_in_progress":"システム更新が進行中","checking_maintenance_mode":"メンテナンスモードのステータスを確認中..."},"__zh":{"maintenance_in_progress":"正在维护","restoring_backup":"正在恢复备份","system_scale_in_progress":"正在扩展系统","system_update_in_progress":"正在更新系统","checking_maintenance_mode":"正在检查维护模式状态..."},"__ko":{"maintenance_in_progress":"유지 관리 진행 중","restoring_backup":"백업 복원 중","system_scale_in_progress":"시스템 확장 진행 중","system_update_in_progress":"시스템 업데이트 진행 중","checking_maintenance_mode":"유지 관리 모드 상태 확인 중..."}};amdi18n.init=function (language){
		// get the default language
		if(!language){
			if(window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(document.documentElement.lang){
				language = document.documentElement.lang;
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		for(var name in this.__root){
			if(typeof this[name] === 'undefined'){
				this[name] = this.__root[name];
			}
		}
	};amdi18n.init();module.exports=amdi18n;