var amdi18n={"__root":{"feedback":"Feedback"},"__ja":{"feedback":"フィードバック"},"__en":{"feedback":"Feedback"},"__zh":{"feedback":"反馈"},"__ko":{"feedback":"피드백"}};amdi18n.init=function (language){
		// get the default language
		if(!language){
			if(window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(document.documentElement.lang){
				language = document.documentElement.lang;
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		for(var name in this.__root){
			if(typeof this[name] === 'undefined'){
				this[name] = this.__root[name];
			}
		}
	};amdi18n.init();module.exports=amdi18n;