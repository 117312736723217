define([
	'text!core/widgets/make-a-wish/stars.html'
	], function(
		Template
	) {

	const Stars = {

        init() {
            this.elem = $(Template);
            this.elem.find(".star").click(function() {
                Stars.selectStar($(this));
            });
            return this.elem;
        },

        selectStar($star) {
            this.elem.find(".star").removeClass("_active");
            $star.addClass("_active")
                .prevAll()
                .addClass("_active");
        },

        getRating() {
            return this.elem.find(".star._active").length;
        }

	};

	return Stars;
});