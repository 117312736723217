define({
    "hide_package":"\u30d1\u30c3\u30b1\u30fc\u30b8\u306e\u975e\u8868\u793a",
    "show_package":"\u30d1\u30c3\u30b1\u30fc\u30b8\u306e\u8868\u793a",
    "fetch_package_problem":"\u30d1\u30c3\u30b1\u30fc\u30b8\u30ea\u30b9\u30c8\u306e\u53d6\u5f97\u306b\u554f\u984c\u304c\u767a\u751f\u3057\u307e\u3057\u305f",
    "dna_center":"DNA Center",
    "appversion":"\u30d0\u30fc\u30b8\u30e7\u30f3",
    "release_note":"\u30ea\u30ea\u30fc\u30b9\u30ce\u30fc\u30c8",
    "copyright": "2018 Cisco Systems Inc. All Rights Reserved."
});

