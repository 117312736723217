define(['jquery'],
function($) {
	return {
		tracePath: function(data, fn, fnerror) {
			var POST = data;
		    $.ajax({
		        url: API_URL_PREFIX + "/flow-path",
		        type: "POST",
		        dataType: "json",
		        contentType: "application/json",
		        data: JSON.stringify(POST),
		        success: function(response) {
		            fn(response.response);
		        },
		        error: function(data, t, response) {
		         	fnerror(JSON.parse(data.responseText).response);
		        }
		    });
		}
	};
});