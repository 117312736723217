define({
  api_reference: "API \u30ea\u30d5\u30a1\u30ec\u30f3\u30b9",
  learn: "\u5b66\u7fd2",
  system: "\u30b7\u30b9\u30c6\u30e0",
  help: "\u30d8\u30eb\u30d7",
  about_dna_center: "\u6982\u8981",
  sign_out: "\u30b5\u30a4\u30f3\u30a2\u30a6\u30c8",
  developer_resource: "\u958b\u767a\u8005\u30ea\u30bd\u30fc\u30b9",
  tools: "\u30c4\u30fc\u30eb",
  make_a_wish: "Make a Wish",
  audit_logs: "Audit Logs",
  system_settings: "System Settings"
});
