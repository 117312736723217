define([
  'jquery'
], function($) {
  "use strict";
  return {
    getCurrentRelease : function() {
      var dfd = new $.Deferred();
      $.ajax({
        url :  "/api/system/v1/maglev/packages",
        type  : "GET",
        contentType: 'application/json',
        success : function(data)  {
          var version = "";

          var i = data.response.length;
          while (i--) {
            if (data.response[i].name === "system") {
              version = data.response[i].version;
            }
          }
          dfd.resolve(version);
        },
        error  : function(data)  {
          dfd.reject(data);
        }
      });
      return dfd.promise();
    }
  };
});