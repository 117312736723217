define({
    "thank_you_for_your_feedback": "\u30d5\u30a3\u30fc\u30c9\u30d0\u30c3\u30af\u3092\u304a\u5bc4\u305b\u3044\u305f\u3060\u304d\u3001\u3042\u308a\u304c\u3068\u3046\u3054\u3056\u3044\u307e\u3057\u305f\u3002",
    "label_feedback": "\u30d5\u30a3\u30fc\u30c9\u30d0\u30c3\u30af",
    "label_email": "\u30e1\u30fc\u30eb",
    "label_make_a_suggestion": "\u63d0\u6848\u3059\u308b",
    "label_cancel": "\u30ad\u30e3\u30f3\u30bb\u30eb",
    "label_submit": "\u9001\u4fe1",
    "sending_feedback": "\u30d5\u30a3\u30fc\u30c9\u30d0\u30c3\u30af\u306e\u9001\u4fe1",
    "unable_to_submit_feedback": "DNA Center\u306b\u3042\u306a\u305f\u306e\u30d5\u30a3\u30fc\u30c9\u30d0\u30c3\u30af\u3092\u9001\u4fe1\u3059\u308b\u3053\u3068\u304c\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f\u3002\u63a5\u7d9a\u306b\u554f\u984c\u306e\u3042\u308b\u53ef\u80fd\u6027\u304c\u3042\u308a\u307e\u3059\u3002",
    "label_how_do_you_feel_about_dna": "DNA Center\u306b\u3064\u3044\u3066\u3069\u3046\u304a\u8003\u3048\u3067\u3059\u304b\uff1f",
    "label_name": "\u540d\u524d",
    "enter_suggestion_feedback_portal": "\u5fc5\u8981\u306a\u5834\u5408\u306f\u3001\u30d5\u30a3\u30fc\u30c9\u30d0\u30c3\u30af\u30dd\u30fc\u30bf\u30eb\u3092\u4f7f\u7528\u3057\u3066\u76f4\u63a5\u3054\u63d0\u6848\u3092\u5165\u529b\u3067\u304d\u307e\u3059\u3002",
    "enter_suggestion_feedback_portal_markup_string": "\u30d5\u30a3\u30fc\u30c9\u30d0\u30c3\u30af\u30dd\u30fc\u30bf\u30eb\u3078",
    "cisco_may_contact_me_about_feedback": "Cisco\u304b\u3089\u3001\u3044\u305f\u3060\u3044\u305f\u30d5\u30a3\u30fc\u30c9\u30d0\u30c3\u30af\u306b\u3064\u3044\u3066\u9023\u7d61\u3059\u308b\u53ef\u80fd\u6027\u304c\u3042\u308a\u307e\u3059",
    "make_wish": "Make a Wish",
    "make_wish_style":"-77px"
});
