const axios = require("axios");

axios.interceptors.request.use(config => {
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  config.headers["X-CSRF-TOKEN"] = localStorage.getItem("csrf");
  if (config.preventCache) {
    config.params = Object.assign({}, config.params, {
      __preventCache: new Date().getTime()
    });
  }
  return config;
});

module.exports = axios;