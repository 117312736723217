define({
"root":{
    "digits_only":"Use digits only",
    "digits_and_spaces_only":"Use digits and spaces only",
    "digits_letters_only":"Use digits and letters only"
},
"en":true,
"ja":true,
"zh":true,
"ko":true
});

