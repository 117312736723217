define([
  "jquery",
  "core/widgets/make-a-wish/api",
  "apicem-widgets/widgets/form/checkbox/Checkbox",
  "text!core/widgets/make-a-wish/Wish.html",
  "text!core/widgets/make-a-wish/Wish.error.html",
  "apicem-widgets/widgets/form/textField/TextField",
  "apicem-widgets/widgets/form/button/Button",
  "apicem-widgets/widgets/form/TextArea",
  "apicem-widgets/widgets/modal/confirm/confirm",
  "core/widgets/make-a-wish/stars",
  "i18n!./nls/i18_makeAWish",
  "core/utils/i18n"
], function(
  $,
  api,
  Checkbox,
  Template,
  TemplateError,
  TextField,
  Button,
  TextArea,
  Confirm,
  Stars,
  i18nMessageBundle,
  i18nUtil
) {
  const bundleStrings = {
    label_feedback: i18nMessageBundle.label_feedback,
    label_how_do_you_feel_about_dna: i18nMessageBundle.label_how_do_you_feel_about_dna,
    sending_feedback: i18nMessageBundle.sending_feedback
  };
  const wishTemplate = i18nUtil.getFilledTextForHBS(Template, bundleStrings);
  let form = {
    name: null,
    email: null,
    feedback: null,
    screenshot: null,
    submit: null,
    cancel: null,
    testing: null
  }; // Holds each widget
  let $form; // jQuery obj to form container

  const _parseSpecialCharactersWhichAreNotAllowedByApi = str => str.replace(/</g, "less than").replace(/>/g, "more than");

  const Wish = {
    parse: function() {
      const json = {
        email: form.email.getVal(),
        name: form.name.getVal(),
        feedback: _parseSpecialCharactersWhichAreNotAllowedByApi(form.feedback.getVal()),
        rating: Stars.getRating() * 2,
        userTesting: form.testing.checked()
      };

      // TODO: depecrated
      // if (form.screenshot) {
      // 	json.blob = form.screenshot;
      // }

      return api
        .getPackages()
        .then(response => {
          let packages = response.map(n => n.fqn);
          json.packages = packages;
          return json;
        })
        .then(json => {
          if (!json.name || !json.feedback || !json.email) {
            throw new Error("Invalid Form");
          } else {
            return json;
          }
        });
    },

    sendFeedback() {
      Wish.showLoading();
      Wish.parse()
        .then(json => {
          api
            .sendWish(json)
            .then(Wish.showThanks)
            .catch(Wish.showError);
        })
        .catch(err => {
          if (err == "Invalid Form") {
            return;
          }
          Wish.showError(err);
        });
      return;
    },

    showLoading() {
      $form.find("._form-loading").removeClass("hide");
    },

    hideLoading() {
      $form.find("._form-loading").removeClass("hide");
    },

    showThanks() {
      Wish.hideLoading();
      Wish.hideForm();
      Confirm.ask(i18nMessageBundle.thank_you_for_your_feedback, {
        type: "success",
        hideCancelButton: true
      });
    },

    renderFeedbackError() {
      const orig = i18nMessageBundle.enter_suggestion_feedback_portal;
      const markupStr = i18nMessageBundle.enter_suggestion_feedback_portal_markup_string;
      const parts = orig.split(markupStr);
      return (
        parts[0] +
        "<a href=\"https://dnacenter.uservoice.com/\" target=\"_blank\">" +
        markupStr +
        "" +
        parts[1] +
        "<i class=\"fa fa-external-link\" aria-hidden=\"true\"></i></a>"
      );
    },

    showError(err) {
      const bundleErrorStrings = {
        unable_to_submit_feedback: i18nMessageBundle.unable_to_submit_feedback,
        enter_suggestion_feedback_portal: i18nMessageBundle.Wish ?
          i18nMessageBundle.Wish.renderFeedbackError() :
          i18nMessageBundle.enter_suggestion_feedback_portal
      };
      const wishErrorTemplate = i18nUtil.getFilledTextForHBS(
        TemplateError,
        bundleErrorStrings
      );
      console.error("Something went wrong sending a wish: " + err);
      $form.html(wishErrorTemplate);
      $form.find("._close").click(Wish.hideForm.bind(Wish));
    },

    renderForm(initialState = {}) {
      $form = $(wishTemplate);

      form.name = new TextField($form.find(".input-name"), {
        label: i18nMessageBundle.label_name,
        required: true,
        keyup: this.toggleSubmitEnabled.bind(this),
        regex: "alphabetHyphens"
      });
      form.email = new TextField($form.find(".input-email"), {
        label: i18nMessageBundle.label_email,
        required: true,
        regex: "email",
        keyup: this.toggleSubmitEnabled.bind(this)
      });
      form.feedback = new TextArea($form.find(".input-feedback"), {
        label: i18nMessageBundle.label_make_a_suggestion,
        required: true,
        keyup: this.toggleSubmitEnabled.bind(this),
        setVal: initialState.feedback
      });

      form.testing = new Checkbox($form.find(".input-testing"), {
        label: i18nMessageBundle.cisco_may_contact_me_about_feedback,
        checked: true
      });

      form.cancel = new Button(null, {
        setText: i18nMessageBundle.label_cancel,
        click: this.hideForm.bind(this)
      });

      form.submit = new Button(null, {
        setText: i18nMessageBundle.label_submit,
        theme: "blue",
        click: this.sendFeedback.bind(this),
        enabled: false
      });

      $form.find(".cta-bar").append([form.cancel.domNode, form.submit.domNode]);
      $form.find("._close").click(this.hideForm.bind(this));

      $form.find(".stars").html(Stars.init());

      return $form;
    },

    toggleSubmitEnabled() {
      const name = form.name.getVal(false);
      const email = form.email.isValid();
      const feedback = form.feedback.getVal(false);

      if (!name || !feedback || !email) {
        form.submit.enabled(false);
      } else {
        form.submit.enabled(true);
      }
    },

    // TODO: Deprecated
    showScreenshot(dataUrl) {
      $form.find(".input-screenshot").addClass("hide");
      $form.find(".screenshot-result").removeClass("hide");
      $form
        .find(".screenshot-result .img-result")
        .html("<img class='screenshot' src='" + dataUrl + "'>");
      form.screenshot = dataUrl;
    },

    initForm() {
      $(".make-a-wish-sidebar").append(Wish.renderForm());
      $(".make-a-wish-pin").click(this.toggleShow.bind(this));
      $(".make-a-wish-pin").html(i18nMessageBundle.make_wish);
      $(".make-a-wish-pin").css("left", i18nMessageBundle.make_wish_style);
      $(".make-a-wish-pin").css("display", "block");
    },

    hideForm() {
      $(".make-a-wish-sidebar").removeClass("open");
    },

    showForm(initialState) {
      $(".make-a-wish-sidebar").addClass("open");
      Wish.resetForm(initialState);
    },

    toggleShow() {
      if ($(".make-a-wish-sidebar").hasClass("open")) {
        Wish.hideForm();
      } else {
        Wish.showForm();
      }
    },

    resetForm(initialState) {
      $("#make-a-wish-form").remove();
      $(".make-a-wish-sidebar").append(Wish.renderForm(initialState));
    },

    // TODO: Deprecated
    takeScreenshot() {
      $(".modal").attr("data-html2canvas-ignore", true);
      return new Promise(function(resolve, reject) {
        html2canvas(document.body, {
          onrendered: function(canvas) {
            var screenshot = canvas.toDataURL("image/png");
            resolve(screenshot);
          }
        });
      });
    }
  };

  return {
    init() {
      Wish.initForm();
    },

    /**
     *
     * @param {object} initialState
     * @param {string} initialState.feedback
     */
    showForm(initialState) {
      Wish.showForm(initialState);
    }
  };
});
