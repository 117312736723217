var amdi18n={"__root":{"learn":"Learn","api_reference":"API Reference","system":"System","help":"Help","about_dna_center":"About","sign_out":"Sign Out","developer_resource":"Developer Resources","tools":"Tools","make_a_wish":"Make a Wish","audit_logs":"Audit Logs","system_settings":"System Settings"},"__en":{"api_reference":"API Reference","learn":"Learn","system":"System","help":"Help","about_dna_center":"About","sign_out":"Sign Out","developer_resource":"Developer Resources","contact_support":"Contact Support","tools":"Tools","make_a_wish":"Make a Wish","audit_logs":"Audit Logs","system_settings":"System Settings"},"__ja":{"api_reference":"API リファレンス","learn":"学習","system":"システム","help":"ヘルプ","about_dna_center":"概要","sign_out":"サインアウト","developer_resource":"開発者リソース","tools":"ツール","make_a_wish":"Make a Wish","audit_logs":"Audit Logs","system_settings":"System Settings"},"__zh":{"learn":"学习","api_reference":"API 参考","system":"系统","help":"帮助","about_dna_center":"关于","sign_out":"注销","developer_resource":"开发资源","tools":"工具","make_a_wish":"改进建议","audit_logs":"Audit Logs","system_settings":"System Settings"},"__ko":{"api_reference":"API 참조","learn":"학습","system":"시스템","help":"도움말","about_dna_center":"정보","sign_out":"로그아웃","developer_resource":"개발자 리소스","tools":"도구","make_a_wish":"바라는 점","audit_logs":"Audit Logs","system_settings":"System Settings"}};amdi18n.init=function (language){
		// get the default language
		if(!language){
			if(window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(document.documentElement.lang){
				language = document.documentElement.lang;
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		for(var name in this.__root){
			if(typeof this[name] === 'undefined'){
				this[name] = this.__root[name];
			}
		}
	};amdi18n.init();module.exports=amdi18n;