define([
	'jquery'
],	function($) {
	return {
		getServices: function() {
			var dfd = new $.Deferred();
			$.ajax({
				type: 'GET',
				contentType: "application/json",
				url: "/api/system/v1/maglev/appstacks/fusion/services",
				dataType: "json",
				success: function(response) {
				  dfd.resolve(response);
				},
				error: function(error) {
					dfd.reject(error);
				}
			});
			return dfd.promise();
		},
		updateLogLevel: function(data) {
			var dfd = new $.Deferred();
			var logData = {
			name: data.name,
			level: data.logLevel,
			timeout:data.timeout
			};
			$.ajax({
				data: JSON.stringify(logData),
				type: 'PUT',
				contentType: "application/json",
				url: window.API_URL_PREFIX + "/logger/level/"+data.service,
				dataType: "json",
				success: function(response) {
					dfd.resolve(response);
				},
				error: function(error) {
					dfd.reject(error);
				}
			});
			return dfd.promise();
		}
  }
});