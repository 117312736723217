define(["jquery", "underscore", "core/apicem/utils/localStorage"], function(
  $,
  _,
  localStorage
) {
  const STORAGE_KEY = "cisco.dna.core.intents";
  const AUTH_SOURCE_STORAGE_KEY = "authSource";

  let intents = {};
  let authSource;

  function _getIntentsRest() {
    return window.appcontext.authSkip
      ? Promise.reject("running in auth skip mode")
      : new Promise((resolve, reject) => {
        $.ajax({
          type: "GET",
          contentType: "application/json",
          url: "/api/system/v1/identitymgmt/intents",
          dataType: "json",
          success: function(response) {
            resolve(response);
          },
          error: function(error) {
            reject(error);
          }
        });
      });
  }

  function _getUserAuthSource() {
    return window.appcontext.authSkip
      ? Promise.reject("running in auth skip mode")
      : new Promise((resolve, reject) => {
        $.ajax({
          type: "GET",
          contentType: "application/json",
          url: "/api/system/v1/identitymgmt/users",
          dataType: "json",
          success: res => {
            const userData = res.response.users.filter(
              user => user.username === (window.USERNAME || "")
            )[0];
            resolve(userData ? userData.authSource : undefined);
          },
          error: error => {
            reject(error);
          }
        });
      });
  }

  function _getPermittedOperations(resourceName) {
    const intent = intents[resourceName];
    if (intent) {
      return intent.operationNameSet || [];
    } else {
      console.warn("Resource not found in intent list", resourceName);
      return [];
    }
  }

  return {
    init() {
      return new Promise(resolve => {
        const cachedIntents = JSON.parse(localStorage.getItem(STORAGE_KEY) || "null");
        const cachedAuthSource = JSON.parse(
          localStorage.getItem(AUTH_SOURCE_STORAGE_KEY) || "null"
        );

        if (!cachedIntents || !cachedAuthSource) {
          Promise.all([_getIntentsRest(), _getUserAuthSource()]).then(
            responses => {
              intents = _.indexBy(
                responses[0].response.permissions || [],
                "resourceName"
              );
              localStorage.setItem(STORAGE_KEY, JSON.stringify(intents));

              authSource = responses[1];
              localStorage.setItem(AUTH_SOURCE_STORAGE_KEY, JSON.stringify(authSource));

              resolve();
            },
            error => {
              console.error("Failed to get intent list:", error);
              resolve();
            }
          );
        } else {
          intents = cachedIntents;
          authSource = cachedAuthSource;
          resolve();
        }
      });
    },
    getPermittedOperations: _getPermittedOperations,
    hasResource(resourceName) {
      return !!intents[resourceName];
    },
    hasPermission(resourceName, operationName) {
      return _getPermittedOperations(resourceName).indexOf(operationName) !== -1;
    },
    isExternalAuthSource() {
      return authSource && authSource === "external";
    },
    clearCached() {
      localStorage.removeItem(STORAGE_KEY);
      localStorage.removeItem(AUTH_SOURCE_STORAGE_KEY);
    }
  };
});
