/* Tracks how long a user has been on a page, and updates
	Stores duration in cache and on page load will POST it to telemetry */
define(["jquery", "core/utils/serviceRegistry"], function($, sr) {
  "use strict";

  let start_time;

  /**
   * Get start time and check if there is data cached
   * @return {undefined}
   */
  function _init() {
    _resetStats();
    _checkCache();
    $(window).unload(function() {
      _store(_getStats());
    });

    // Add a hook to pushState to grab stats for times where
    // page changes but the window doesn't unload (history.pushState) on tabs
    const pushState = history.pushState;
    history.pushState = function(state) {
      _post(_getStats());
      _resetStats();
      // ... whatever else you want to do
      // maybe call onhashchange e.handler
      return pushState.apply(history, arguments);
    };
  }

  /**
   * Check local storage for duration status, and reset
   * @return {undefined}
   */
  function _checkCache() {
    let cache = localStorage.getItem("user-analytics");

    if (cache) {
      cache = JSON.parse(cache);
      _post(cache);
      localStorage.setItem("user-analytics", null);
    }
  }

  /**
   * Gather current stats
   * @return {json} Stats of current page
   */
  function _getStats() {
    const end_time = new Date();

    const duration = end_time - start_time;
    const uri = window.location.pathname + window.location.hash;
    const search = window.location.search;
    return {
      href: uri,
      search,
      page_load: _loadTime.toString(),
      page_start: start_time.toString(),
      duration: duration.toString()
    };
  }

  /**
   * Initializes new stats tracking
   * @return {undefined}
   */
  function _resetStats() {
    start_time = new Date();
  }

  /**
   * Stores stats into local storage
   * @return {undefined}
   */
  function _store(stats) {
    localStorage.setItem("user-analytics", JSON.stringify(stats));
  }

  /**
   * Send statistics to Telemetry
   * @param  {Object} data Page statistics from cache
   * @return {}
   */
  async function _post(data) {
    if (data) {
      const POST = {
        serviceName: "ui",
        uri1: data.href,
        uri2: data.search,
        responseType: "200",
        avgExecutionTime: data.page_load,
        avgUsageDuration: data.duration,
        noOfExecution: 1
      };

      const telemetry = await sr.getService("cisco.dna.core.telemetry", "telemetry");
      telemetry.postStats(POST);
    }
  }

  return {
    init: _init
  };
});
