define([
    'jquery'
], function($) {

    // holds the list of tasks so multiple tasks can run at once
    var GVTaskList = {
        tasks: [],
        addTask: function(gvtask, withData, type) {
            var task = new GVTaskList.Task(gvtask, withData, type);
            this.tasks.push(task);
            task.start();
        }
    };

    /* User interface */
    function GVTask(URL, opt) {
        var self = this;
        this.url = URL;

        opt = opt || {};

        // The interval in which to poll the task status, in milliseconds
        this.interval = opt.interval || 1000;
        // To send a notification once the task is complete.
        this.notification = opt.notifyOnDone || null;
        // To send a notification when the task has failed
        this.errorMessage = opt.notifyError || null;
        this.urlparams = opt.urlparams || null;
        // The URL Prefix of the API
        this.urlPrefix = opt.urlPrefix || window.API_URL_PREFIX;
        // Set this option if your urlPrefix is different from the task API
        this.taskUrlPrefix = opt.taskUrlPrefix || this.urlPrefix;
        //the headers
        this.headers = opt.headers;

        // Call this method with your data to initialize the task
        // There's one for each method: POST, DELETE, or PUT
        this.start = function(withData) {
            GVTaskList.addTask(self, withData, "POST");
        };
        this.startDelete = function(url) {
            GVTaskList.addTask(self, url, "DELETE");
        };
        this.startDeleteWithData = function(withData) {
            GVTaskList.addTask(self, withData, "DELETE_WITH_DATA");
        };
        this.startPut = function(withData) {
            GVTaskList.addTask(self, withData, "PUT");
        };
        this.startFile = function(withData) {
            GVTaskList.addTask(self, withData, "FILE");
        };
    }

    /* Events */
    /* Override these in your code */
    // Each method returns the result from the task
    GVTask.prototype.onstart = function(task_response) {};
    GVTask.prototype.onupdate = function(task_response) {};
    GVTask.prototype.onsuccess = function(task_response) {};

    /*
     * Following are the failure types
     * requestFailed
     * taskFailed
     * taskError
     *
     * details: {
            "type": "requestFailed",
            "response": response
     *  }
     */
    GVTask.prototype.onfailure = function(details) {};

    /* WARNING: Following events are deprecated. Alternate for these events is onfailure */
    GVTask.prototype.onfail = function() {};
    GVTask.prototype.onerror = function() {};
    GVTask.prototype.onretrystop = function() {};




    /*
     *   Task Pollar class
     * @param URL api path
     * @param DATA json data for API call
     * @param opt with following properties
     *   interval - interval for polling, default is 100ms
     */
    GVTaskList.Task = function(gvTask, withData, type) {
        var self = this;
        var ajax = this.ajax;
        var gvtask = this.gvtask = gvTask;
        var data = this.data = withData;

        // Number of retries if a task status has failed
        this.retries = 0;
        // This will be set to true when the interval hits, and will stay true until the previous request completes
        this.performPoll = false;
        this.toPoll = null;

        type = this.type = type;

        this.timer = setInterval(function() {
            self.performPoll = true;
            if (self.toPoll) {
                // if task has already responded and queued the next one poll it
                self.poll(self.toPoll);
                self.toPoll = null;
            }
        }, gvtask.interval);

        // Get status update of task
        this.poll = function(scope) {
            if (scope) {
                ajax.getStatus(scope);
            } else {
                // if enough time has passed perform the next poll on response otherwise queue and wait for interval
                if (gvtask.performPoll) {
                    ajax.getStatus(this);
                    gvtask.performPoll = false;
                    gvtask.toPoll = null;
                } else {
                    this.toPoll = this;
                }
            }
        };

        // Start the task
        this.start = function() {
            if (self.type == "POST") {
                ajax.startPost(gvtask, self.data, function(response) {
                    self.started(response);
                }, function(response) {
                    gvtask.onerror(response);
                    gvtask.onfailure({
                        "type": "requestFailed",
                        "response": response
                    });
                    gvtask.onfail(response);
                });
            } else if (self.type == "DELETE") {
                ajax.startDelete(gvtask, function(response) {
                    self.started(response);
                }, function(response) {
                    gvtask.onerror(response);
                    gvtask.onfailure({
                        "type": "requestFailed",
                        "response": response
                    });
                    gvtask.onfail(response);
                });
            } else if (self.type === "PUT") {
                ajax.startPut(gvtask, self.data, function(response) {
                    self.started(response);
                }, function(response) {
                    gvtask.onerror(response);
                    gvtask.onfailure({
                        "type": "requestFailed",
                        "response": response
                    });
                    gvtask.onfail(response);
                });
            } else if (self.type === "FILE") {
                ajax.startFilePost(gvtask, self.data, function(response) {
                    self.started(response);
                }, function(response) {
                    gvtask.onerror(response);
                    gvtask.onfailure({
                        "type": "requestFailed",
                        "response": response
                    });
                    gvtask.onfail(response);
                });
            } else if(self.type === 'DELETE_WITH_DATA'){
                ajax.startDelete(gvtask, function(response) {
                    self.started(response);
                }, function(response) {
                    gvtask.onerror(response);
                    gvtask.onfailure({
                        "type": "requestFailed",
                        "response": response
                    });
                    gvtask.onfail(response);
                }, self.data);
            }
        };
        // Once task has been started
        this.started = function(response) {
            this.taskId = response.taskId || response.task_id;
            // Trigger event
            gvtask.onstart(response);

            self.poll();

            if (gvtask.notification) {
                ajax.backendPoll(self.taskId, gvtask.notification, gvtask.errorMessage, gvtask.urlparams);
            }
        };

        // When a status update comes back
        this.onStatusUpdate = function(response) {
            var self = this;
            // task failed
            if (response.isError) {
                clearInterval(this.timer);
                this.timer = null;
                ajax.getTree(this, function(tree) {
                    gvtask.onfailure({
                        "type": "taskError",
                        "response": response,
                        "tree": tree
                    });
                    gvtask.onfail(response, tree);
                });
            } else if (response.endTime) {
                clearInterval(this.timer);
                this.timer = null;
                ajax.getTree(this, function(tree) {
                    gvtask.onsuccess(response, tree);
                });
            } else {
                if (this.retries >= 3) {
                    console.error("Maximum retry count reached. GVTask has stopped trying");
                    gvtask.onretrystop();
                    clearInterval(this.timer);
                    this.timer = null;
                    gvtask.onfailure({
                        "type": "taskFailed",
                        "response": response
                    });
                } else {
                    // Task is still going
                    ajax.getTree(this, function(tree) {
                        gvtask.onupdate(response, tree);
                        self.poll();
                    });
                }
            }
        };
    };

    /* Ajax calls */
    GVTaskList.Task.prototype.ajax = {
        startPut: function(task, putData, fn, fnError) {
            $.ajax({
                url: task.urlPrefix + task.url,
                type: "PUT",
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify(putData),
                beforeSend: function (request) {
                    request.setRequestHeader('X-CSRF-Token', window.CSRF);
                },  
                success: function(response) {
                    fn(response.response);
                },
                error: function(response) {
                    //self.starterror(response);
                    console.error("Error starting PUT Task:", response);
                    fnError(response);
                }
            });
        },

        startPost: function(task, postData, fn, fnError) {
            $.ajax({
                url: task.urlPrefix + task.url,
                type: "POST",
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify(postData),
		        beforeSend: function (request) {
                    request.setRequestHeader('X-CSRF-Token', window.CSRF);
                },         
       		    success: function(response) {
                    fn(response.response);
                },
                error: function(response) {
                    //self.starterror(response);
                    console.error("Error starting POST Task:", response);
                    fnError(response);
                },
                headers: task.headers
            });
        },

        startFilePost: function(task, postData, fn, fnError) {
            $.ajax({
                url: task.urlPrefix + task.url,
                type: "POST",
                contentType: false,
                processData: false,
                data: postData,
                beforeSend: function (request) {
                    request.setRequestHeader('X-CSRF-Token', window.CSRF);
                },  
                success: function(response) {
                    fn(response.response);
                },
                error: function(response) {
                    //self.starterror(response);
                    console.error("Error starting File POST Task:", response);
                    fnError(response);
                }
            });
        },

        startDelete: function(task, fn, fnError, postData) {
            $.ajax({
                url: task.urlPrefix + task.url,
                type: "DELETE",
                contentType: "application/json;charset=UTF-8",
                beforeSend: function (request) {
                    request.setRequestHeader('X-CSRF-Token', window.CSRF);
                },  
                success: function(response) {
                    fn(response.response);
                },
                headers: task.headers,
                error: function(response) {
                    //self.starterror(response);
                    console.error("Error starting DELETE Task:", response);
                    fnError(response);
                },
                data: JSON.stringify(postData),

            });
        },

        getStatus: function(self) {
            //var endPoint = self.gvtask.urlPrefix + "/task/";
            var endPoint = self.gvtask.taskUrlPrefix + "/task/";
            $.get(endPoint + self.taskId, function(response) {
                self.onStatusUpdate(response.response);
            }).fail(function(response) {
                console.error("Error getting Task info");
                self.retries++;
                self.onStatusUpdate({});
            });
        },

        getTree: function(self, fn) {
            //var endPoint = self.gvtask.urlPrefix + "/task/";
            var endPoint = self.gvtask.taskUrlPrefix + "/task/";
            $.get(endPoint + self.taskId + "/tree", function(response) {
                fn(response.response);
            });
        },

        backendPoll: function(taskId, notification, errorNotification, urlparams) {
            if (urlparams) {
                urlparams = urlparams.replace("{TASKID}", taskId);
            }
            var data = {
                taskId: taskId,
                notification: notification,
                errorNotification: errorNotification,
                appuuid: window.appcontext.uuid,
                urlparams: urlparams
            };

        }
    };
    return GVTask;
});
