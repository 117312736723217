define({
    "thank_you_for_your_feedback": "Thank you for your feedback!",
    "label_feedback": "Feedback",
    "label_email": "Email",
    "label_make_a_suggestion": "Make a Suggestion",
    "label_cancel": "Cancel",
    "label_submit": "Submit",
    "sending_feedback": "Sending feedback",
    "unable_to_submit_feedback": "Looks like we were unable to submit your feedback through DNA Center. There may be a problem connecting.",
    "label_how_do_you_feel_about_dna": "How do you feel about DNA Center?",
    "label_name": "Name",
    "enter_suggestion_feedback_portal": "If you'd like, you can enter your suggestion directly through our feedback portal",
    "enter_suggestion_feedback_portal_markup_string": "through our feedback portal",
    "cisco_may_contact_me_about_feedback": "Cisco may contact me about my feedback",
    "make_wish": "Make a Wish",
    "make_wish_style":"-77px"
});
