define([
	'jquery',
	'core/utils/GVTask'
],	function($, GVTask)	{
	"use strict";
	return {
		//valid Types : CLI, SNMPV2_READ_COMMUNITY, SNMPV2_WRITE_COMMUNITY, SNMPV3
		getGlobalCredentials : function(credentialType)	{
			var dfd = new $.Deferred();

			$.ajax({
				url		:	window.API_URL_PREFIX + "/global-credential?credentialSubType=" + credentialType,
				type	:	"GET",
				success	:	function(data)	{
					dfd.resolve(data.response);
				},
				 error	:	function(data)	{
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		deleteGlobalCredentials : function(id) {
	
			var dfd = new $.Deferred();
			var delete_cred = new GVTask("/global-credential/" + id, {
				//interval: 500, // optional.. default 500
				//notifyOnDone: "Device credentials successfully added!",
				type : "DELETE" 
			});

			/* Some Events */
			// When task is started
			delete_cred.onstart = function()  {
				// console.log("[Deleting Global Credentials]");
			};
			// On each update status
			delete_cred.onupdate = function(status) {
				// console.log("[Global Credentials deletion task..]");
			};
			// When task is successful
			delete_cred.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			delete_cred.onerror = function(status) {
				dfd.reject(status);
			}
			// When task fails.
			delete_cred.onfail = function(status, tree) {

				dfd.reject(status);
			};

			// Start task
			delete_cred.startDelete(id);

			return dfd.promise();
		},
		getSNMPProperty :  function() {
			var dfd = new $.Deferred();

			$.ajax({
				url		:	window.API_URL_PREFIX + "/snmp-property",
				type	:	"GET",
				success	:	function(data)	{
					dfd.resolve(data.response);
				},
				 error	:	function(data)	{
					dfd.reject(data);
				}
			});
			return dfd.promise();
		},
		/* data Example
			[
			{
				"systemPropertyName":"Retry",
				"intValue":3
			},
			{
				"systemPropertyName":"Timeout",
				"intValue":3
			},
			{
				"systemPropertyName":"Port",
				"intValue":3
			}
			]
		*/
		addSNMPProperty : function(data) {
	
			var dfd = new $.Deferred();
			var addProp = new GVTask("/snmp-property", {
				type	: "POST" ,
				data	: data
			});

			/* Some Events */
			// When task is started
			addProp.onstart = function()  {
				// console.log("[Adding SNMP Property details]");
			};
			// On each update status
			addProp.onupdate = function(status) {
				// console.log("[SNMP Property details adding task..]");
			};
			// When task is successful
			addProp.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			addProp.onerror = function(status) {
				dfd.reject(status);
			}
			// When task fails.
			addProp.onfail = function(status, tree) {

				dfd.reject(status);
			};

			// Start task
			addProp.start(data);

			return dfd.promise();
		},
		/*
			[{
				"username":"cisco",
				"password":"cisco",
				"enablePassword":"cisco"
			}]
		 */
		addCLICredentials : function(data) {
	
			var dfd = new $.Deferred();
			var addCLI = new GVTask("/global-credential/cli", {
				//interval: 500, // optional.. default 500
				//notifyOnDone: "Device credentials successfully added!",
				type : "POST" 
			});

			/* Some Events */
			// When task is started
			addCLI.onstart = function()  {
				// console.log("[Adding device credentials]");
			};
			// On each update status
			addCLI.onupdate = function(status) {
				// console.log("[Device credentials adding task..]");
			};
			// When task is successful
			addCLI.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			addCLI.onerror = function(status) {
				dfd.reject(status);
			}
			// When task fails.
			addCLI.onfail = function(status, tree) {

				dfd.reject(status);
			};

			// Start task
			addCLI.start(data);

			return dfd.promise();
		},
		/*
			[{
			"username":"cisco1",
			"snmpMode":"AUTH_PRIV",
			"privacyType":"CBC_DES",
			"privacyPassword":"root",
			"authType":"HMAC_SHA",
			"authPassword":"public123"
			}]
		*/
		addSNMPv3: function(data) {
			var dfd = new $.Deferred();
			var addSNMPv3 = new GVTask("/global-credential/snmpv3", {
				//interval: 500, // optional.. default 500
				//notifyOnDone: "Device credentials successfully added!",
				type : "POST" 
			});

			/* Some Events */
			// When task is started
			addSNMPv3.onstart = function()  {
				// console.log("[Adding SNMPv3 credentials]");
			};
			// On each update status
			addSNMPv3.onupdate = function(status) {
				// console.log("[SNMPv3 credentials adding task..]");
			};
			// When task is successful
			addSNMPv3.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			addSNMPv3.onerror = function(status) {
				dfd.reject(status);
			}
			// When task fails.
			addSNMPv3.onfail = function(status, tree) {

				dfd.reject(status);
			};

			// Start task
			addSNMPv3.start(data);

			return dfd.promise();
		},

		addSNMPv2WriteCommunity : function(data) {
	
			var dfd = new $.Deferred();
			var addWrite = new GVTask("/global-credential/snmpv2-write-community", {
				//interval: 500, // optional.. default 500
				//notifyOnDone: "Device credentials successfully added!",
				type : "POST" 
			});

			/* Some Events */
			// When task is started
			addWrite.onstart = function()  {
				// console.log("[Adding snmpv2-write-community]");
			};
			// On each update status
			addWrite.onupdate = function(status) {
				// console.log("[snmpv2-write-community adding task..]");
			};
			// When task is successful
			addWrite.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			addWrite.onerror = function(status) {
				dfd.reject(status);
			}
			// When task fails.
			addWrite.onfail = function(status, tree) {

				dfd.reject(status);
			};

			// Start task
			addWrite.start(data);

			return dfd.promise();
		},
		addSNMPv2ReadCommunity: function(data) {
	
			var dfd = new $.Deferred();
			var addRead = new GVTask("/global-credential/snmpv2-read-community", {
				//interval: 500, // optional.. default 500
				//notifyOnDone: "Device credentials successfully added!",
				type : "POST" 
			});

			/* Some Events */
			// When task is started
			addRead.onstart = function()  {
				// console.log("[Adding snmpv2-read-community]");
			};
			// On each update status
			addRead.onupdate = function(status) {
				// console.log("[snmpv2-read-community adding task..]");
			};
			// When task is successful
			addRead.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			addRead.onerror = function(status) {
				dfd.reject(status);
			}
			// When task fails.
			addRead.onfail = function(status, tree) {

				dfd.reject(status);
			};

			// Start task
			addRead.start(data);

			return dfd.promise();
		},
		addHttpsReadCommunity: function(data) {
	
			var dfd = new $.Deferred();
			var addHttpsRead = new GVTask("/global-credential/http-read", {
				//interval: 500, // optional.. default 500
				//notifyOnDone: "Device credentials successfully added!",
				type : "POST" 
			});

			/* Some Events */
			// When task is started
			addHttpsRead.onstart = function()  {
				// console.log("[Adding https-read-community]");
			};
			// On each update status
			addHttpsRead.onupdate = function(status) {
				// console.log("[https-read-community adding task..]");
			};
			// When task is successful
			addHttpsRead.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			addHttpsRead.onerror = function(status) {
				dfd.reject(status);
			}
			// When task fails.
			addHttpsRead.onfail = function(status, tree) {

				dfd.reject(status);
			};

			// Start task
			addHttpsRead.start(data);

			return dfd.promise();
		},
		addHttpsWriteCommunity: function(data) {
	
			var dfd = new $.Deferred();
			var addHttpsWrite = new GVTask("/global-credential/http-write", {
				//interval: 500, // optional.. default 500
				//notifyOnDone: "Device credentials successfully added!",
				type : "POST" 
			});

			/* Some Events */
			// When task is started
			addHttpsWrite.onstart = function()  {
				// console.log("[Adding https-write-community]");
			};
			// On each update status
			addHttpsWrite.onupdate = function(status) {
				// console.log("[https-write-community adding task..]");
			};
			// When task is successful
			addHttpsWrite.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			addHttpsWrite.onerror = function(status) {
				dfd.reject(status);
			}
			// When task fails.
			addHttpsWrite.onfail = function(status, tree) {

				dfd.reject(status);
			};

			// Start task
			addHttpsWrite.start(data);

			return dfd.promise();
		},

		addNetConfPort: function(data) {
			var dfd = new $.Deferred();
			var addPort = new GVTask("/global-credential/netconf", {
			//interval: 500, // optional.. default 500
			//notifyOnDone: "Device credentials successfully added!",
			type : "POST" 
			});

			/* Some Events */
			// When task is started
			addPort.onstart = function()  {
			// console.log("[Adding NETCONF Port]");
			};
			// On each update status
			addPort.onupdate = function(status) {
			// console.log("[NETCONF Port adding task..]");
			};
			// When task is successful
			addPort.onsuccess = function(status)  {
			dfd.resolve(status);
			};
			addPort.onerror = function(status) {
			dfd.reject(status);
			}
			// When task fails.
			addPort.onfail = function(status, tree) {

			dfd.reject(status);
			};

			// Start task
			addPort.start(data);

			return dfd.promise();
		}
	}
});