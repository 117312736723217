define(["jquery", "rest/telemetry/telemetry", "i18n!./nls/i18_api"], function($, telem, i18nMessageBundle) {
  var urls = {
    CONNECTION: window.API_URL_PREFIX + "/telemetry/datauploader/uploadstatus/60/0/10",
    UPLOAD: window.API_URL_PREFIX + "/telemetry/telemetryevent",
    PACKAGES: "/api/system/v1/maglev/packages"
  };

  function payload(eventValue) {
    return {
      eventCategory: "UI",
      eventAction: "post",
      eventLabel: i18nMessageBundle.feedback,
      eventValue
    };
  }

  var api = {
    checkConnection: function() {
      return new Promise(function(resolve, reject) {
        $.get(urls.CONNECTION)
          .then(resolve)
          .fail(reject);
      });
    },

    getPackages: function() {
      return new Promise(function(resolve, reject) {
        $.get(urls.PACKAGES)
          .then(response => {
            resolve(response.response);
          })
          .fail(reject);
      });
    }
  };

  return {
    hasConnection: function() {
      return api.checkConnection();
    },
    getPackages: function() {
      return api.getPackages();
    },
    sendWish: function(data) {
      return telem.postEvent(payload(data));
    }
  };
});
