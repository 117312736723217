define([
  'jquery'
], function($) {
	"use strict";
	return {
	logError : function( request, response ) {
		var dfd = new $.Deferred();
		var POST = {
			request : request,
			response : response
		};
		$.ajax({
			data: JSON.stringify(POST),
			type: 'POST',
			contentType: "application/json",
			url: "/api/logFailure",
			dataType: "json",
			success: function(response) {
				dfd.resolve(response);
			},
			error: function(error) {
				dfd.reject(error);
			}
		});
		return dfd.promise();
	}
  }
});
