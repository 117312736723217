define({
    "thank_you_for_your_feedback": "\uc758\uacac\uc744 \uc8fc\uc154\uc11c \uac10\uc0ac\ud569\ub2c8\ub2e4!",
    "label_feedback": "\ud53c\ub4dc\ubc31",
    "label_email": "\uc774\uba54\uc77c",
    "label_make_a_suggestion": "\uc81c\uc548\ud558\uae30",
    "label_cancel": "\ucde8\uc18c",
    "label_submit": "\uc81c\ucd9c",
    "sending_feedback": "\ud53c\ub4dc\ubc31 \ubcf4\ub0b4\uae30",
    "unable_to_submit_feedback": "Cisco DNA Center\ub97c \ud1b5\ud574 \ud53c\ub4dc\ubc31\uc744 \uc81c\ucd9c\ud558\uc9c0 \ubabb\ud55c \uac83 \uac19\uc2b5\ub2c8\ub2e4. \uc5f0\uacb0\uc5d0 \ubb38\uc81c\uac00 \uc788\uc744 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",
    "label_how_do_you_feel_about_dna": "Cisco DNA Center\uc5d0 \ub300\ud574 \uc5b4\ub5bb\uac8c \uc0dd\uac01\ud558\uc2ed\ub2c8\uae4c?",
    "label_name": "\uc774\ub984",
    "enter_suggestion_feedback_portal": "\uc6d0\ud558\ub294 \uacbd\uc6b0 \ud53c\ub4dc\ubc31 \ud3ec\ud138\uc744 \ud1b5\ud574 \uc9c1\uc811 \uc81c\uc548 \uc0ac\ud56d\uc744 \uc785\ub825\ud558\uc2e4 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",
    "enter_suggestion_feedback_portal_markup_string": "\ud53c\ub4dc\ubc31 \ud3ec\ud138\uc744 \ud1b5\ud574",
    "cisco_may_contact_me_about_feedback": "\ud53c\ub4dc\ubc31\uc5d0 \ub300\ud574 Cisco\ub85c\ubd80\ud130 \uc5f0\ub77d\uc774 \uc62c \uc218 \uc788\uc2b5\ub2c8\ub2e4",
    "make_wish": "\ubc14\ub77c\ub294 \uc810",
    "make_wish_style":"-62px"
});
