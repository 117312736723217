define(['jquery'], function($) {
  return {

    getServicesVersions: function() {
      return new Promise(function(resolve, reject) {
      $.ajax({
          type: 'GET',
          url: "/api/system/v1/maglev/appstacks",
          contentType: 'application/json',
          success: function(data) {
            resolve(data);
          },
          error: function(error){
            // console.log("Failed to Retreive API Document from Service");
            reject(error);
          }
        });
      });
    },
    
    // Get the services
    getServicesDocs: function() {
      return new Promise(function(resolve, reject) {
      $.ajax({
          type: 'GET',
          url: "/api/system/v1/maglev/appstacks/fusion/services",
          contentType: 'application/json',
          success: function(data) {
            resolve(data);
          },
          error: function(error){
            // console.log("Failed to Retreive API Document from Service");
            reject(error);
          }
        });
      });
    },

    // Get the current Instance Title for the service doc
    getInstanceTitle: function(endpoint) {
      return new Promise(function(resolve, reject) {
        $.ajax({
          type: 'GET',
          url: endpoint,
          contentType: 'application/json',
          success: function(data) {
            resolve(data);
          },
          error: function(error){
            // console.log("Failed to Retreive API Document from Service");
            reject(error);
          }
        });
      });
    }
  };
});