define([
  'jquery'
], function($) {
  return {

    getIdleTimeout: function() {
      var dfd = new $.Deferred();
      $.ajax({
        type: 'GET',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/ticket/attribute/idletimeout",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },

    getSessionTimeout: function() {
      var dfd = new $.Deferred();
      $.ajax({
        type: 'GET',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/ticket/attribute/sessiontimeout",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },

    setIdleTimeout: function(seconds) {
      var dfd = new $.Deferred();
      var POST = {
        attribute: "idletimeout",
        value: seconds
      };
      $.ajax({
        data: JSON.stringify(POST),
        type: 'POST',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/ticket/attribute",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },

    setSessionTimeout: function(seconds) {
      var dfd = new $.Deferred();
      var POST = {
        attribute: "sessiontimeout",
        value: seconds
      };
      $.ajax({
        data: JSON.stringify(POST),
        type: 'POST',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/ticket/attribute",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },

    deleteSessionTimeout: function() {
      var dfd = new $.Deferred();
      $.ajax({
        type: 'DELETE',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/ticket/attribute/idletimeout",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },

    deleteIdleTimeout: function() {
      var dfd = new $.Deferred();
      $.ajax({
        type: 'DELETE',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/ticket/attribute/sessiontimeout",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    }


  };
});