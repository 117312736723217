define([
 	'jquery',
 	'core/utils/GVTask'
],	function($, GVTask) {
 	"use strict";
	return {
		getAllLocations : function( ALOffset, ALLimit ) {
			var offset 	=	ALOffset || 1;
			var limit 	=	ALLimit || 500;
			var dfd 	=	new $.Deferred();
			$.ajax({
				url : window.API_URL_PREFIX + "/location/"+ offset + "/" + limit,
				type  : "GET",
				success : function( data )  {
					dfd.resolve( data.response );
				},
				error  : function( error )  {
					dfd.reject( error );
			 	}
			});
			return dfd.promise();
		},
		createLocation : function( location ) {
			var dfd				= new $.Deferred();
			var data 			=	{};
			data["locationName"]= location;
			
			var postTask = new GVTask("/location", {
				//interval: 100, // optional.. default 500
				type : "POST"
			});

			// When task is successful
			postTask.onsuccess = function( status )  {
				dfd.resolve( status );
			};
			// When task fails.
			postTask.onfailure = function( error ) {
				dfd.reject( error.response );
			};

			// Start task
			postTask.start(data);
			return dfd.promise();
		},
		updateLocation : function( locId, newLocName, newLocGeo ) {
 			var data = {id: locId};
 			if (newLocName) {
 				data.locationName = newLocName;
 			}
 			if (newLocGeo || newLocGeo === "") {
 				data.geographicalAddress = newLocGeo;
 			}

			var dfd			= new $.Deferred();
			var updateTask	= new GVTask("/location", {
				//interval: 100,
				type : "PUT"
			});
			updateTask.onsuccess = function( status )  {
				dfd.resolve( status );
			};
			updateTask.onfailure = function( error ) {
				dfd.reject( error.response );
			};
			updateTask.startPut(data);
			return dfd.promise();
		},
		deleteLocation : function( locId, locName ) {
			var dfd			= new $.Deferred();
			var deleteTask	= new GVTask("/location/" + locId, {
				//interval: 100,
				type : "DELETE"
			});
			deleteTask.onsuccess = function(status)  {
				dfd.resolve(status);
			};
			deleteTask.onfailure = function( error ) {
				dfd.reject( error.response );
			};
			deleteTask.startDelete();
			return dfd.promise();
		},
		applyLocToDevice : function( devId, locId ) {
			var dfd			= new $.Deferred();
			var data 		= {id: devId, location: locId};
			var postTask	= new GVTask("/network-device/location", {
				//interval: 100, // optional.. default 500
				type : "POST"
			});
			// When task is started
			postTask.onstart	= function()  {
				// console.log("[Applying location starting]");
			};
			// When task is successful
			postTask.onsuccess	= function( status )  {
				dfd.resolve( status );
			};
			// When task fails.
			postTask.onfailure	= function( error ) {
				dfd.reject( error.response );
			};
			// Start task
			postTask.start(data);
			return dfd.promise();
		},
		deleteLocFromDevice : function( devId ) {
			var dfd			= new $.Deferred();
			var deleteTask 	= new GVTask( "/network-device/" + devId + "/location/", {
				//interval: 100, // optional.. default 500
				type : "DELETE"
			});
			// When task is started
			deleteTask.onstart	= function()  {
				// console.log("[location deletion from the device started]");
			};
			// When task is successful
			deleteTask.onsuccess = function( status )  {
				dfd.resolve( status );
			};
			// When task fails.
			deleteTask.onfailure = function( error ) {
				dfd.reject( error.response );
			};
			// Start task
			deleteTask.startDelete();
			return dfd.promise();
		}
  	}
});