define(["jquery", "../../core/dnacData"], function ($, dnacDataObj) {
  const getEventValue = data => ({
    app: "DNA Center",
    title: document.title,
    platform: (window.navigator || {}).platform,
    userAgent: (window.navigator || {}).userAgent,
    version: dnacDataObj.dnacVersion,
    packageVersion: window.RELEASE_VERSION,
    data
  });

  /**
   * Generate random GUID
   * @return {String}   Random GUID
   */
  const guid = (function() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return function() {
      return (
        s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
      );
    };
  })();

  return Object.freeze({
    postEvent: function(data) {
      const postData = JSON.stringify(
        // TODO: do we want window.location.href instead?
        Object.assign(
          {},
          data,
          {eventURL: window.location.pathname + window.location.search},
          {eventValue: JSON.stringify(getEventValue(data.eventValue))}
        )
      );
      return new Promise((resolve, reject) =>
        $.ajax({
          url: window.API_URL_PREFIX + "/telemetry/telemetryevent",
          type: "POST",
          contentType: "application/json",
          data: postData
        })
          .then(resolve)
          .fail((...stuff) => {
            // console.log("postEvent failed", ...stuff);
            return reject(...stuff);
          })
      );
    },
    postStats: function(data) {
      const postData = JSON.stringify(Object.assign({}, data, {id: guid()}));
      return new Promise((resolve, reject) =>
        $.ajax({
          url: window.API_URL_PREFIX + "/telemetry/telemetryapistats",
          type: "POST",
          contentType: "application/json",
          data: postData
        })
          .then(resolve)
          .fail((...stuff) => {
            // console.log("postStats failed", ...stuff);
            return reject(...stuff);
          })
      );
    }
  });
});
