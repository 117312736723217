define(function(require) {
  const Ajv = require("ajv");
  const _ = require("underscore");
  const internationalize = require("./internationalize");
  const pluginSchema = require("./plugin.schema.json");

  function validateSchema(ajv, schema) {
    var errorMessage = null;
    try {
      ajv.validateSchema(schema);
      if (ajv.errors) errorMessage = ajv.errorsText();
    } catch (e) {
      errorMessage = e.message;
    }
    return errorMessage;
  }

  //
  // For each plugin, add the schema types defined under the
  // "schemaDefinitions" key in its ui-config to the master schema.
  // Types defined in schemaDefinitions can be referenced in other
  // types defined in extensionPoints and other schemaDefinitions,
  // via { "$ref": "definingPluginId/schemaDefinitions#/someType"}
  //
  function appendValidSchemaDefinitions(ajv, pluginConfigs) {
    pluginConfigs.forEach(config => {
      const validTypes = _.reduce(
        config.schemaDefinitions || {},
        function(res, typeSchema, typeName) {
          const errorMessage = validateSchema(ajv, typeSchema);
          if (errorMessage) {
            console.error(
              "Invalid schemaDefinition '%s' in plugin '%s':\n %s",
              typeName,
              config.pluginId,
              errorMessage
            );
          } else {
            res[typeName] = typeSchema;
          }
          return res;
        },
        {}
      );
      if (Object.keys(validTypes).length) {
        ajv.addSchema(config.schemaDefinitions, `${config.pluginId}/schemaDefinitions`);
      }
    });
  }

  function validateExtensionPointDef(ajv, pluginId, extensionPointId, extensionSchema) {
    const errorMessage = validateSchema(ajv, extensionSchema);
    if (errorMessage) {
      console.error(
        "Invalid extension point definition '%s/%s':\n %s\nDefinition:\n%o",
        pluginId,
        extensionPointId,
        errorMessage,
        extensionSchema
      );
    }
    return !errorMessage;
  }

  function validateContribution(
    ajv,
    extensionPointDef,
    extensionPointName,
    receivingPluginId,
    contribution,
    sourcePluginId
  ) {
    // TODO: extensionPointDefs can fail to compile if they
    // have unresolvable $refs, crashing the indexing process
    const validate = ajv.compile(extensionPointDef);
    validate(_.omit(contribution, "extensionPoint", "requiredPermissions"));
    if (validate.errors) {
      console.error(
        "Invalid contribution from '%s' to '%s/%s':\n'%s'\nContribution data:\n%o",
        sourcePluginId,
        receivingPluginId,
        extensionPointName || contribution.extensionPoint,
        ajv.errorsText(validate.errors),
        contribution
      );
      return false;
    }
    return true;
  }

  function validExtensionPointDefs(ajv, pluginConfig) {
    const {pluginId} = pluginConfig;
    return _.reduce(
      pluginConfig.extensionPoints,
      function(res, extensionSchema, extensionPointId) {
        if (validateExtensionPointDef(ajv, pluginId, extensionPointId, extensionSchema)) {
          res[extensionPointId] = extensionSchema;
        }
        return res;
      },
      {}
    );
  }

  function validContributions(
    ajv,
    receivingPlugin,
    extensionPointName,
    sourcePluginId,
    contributions
  ) {
    return _.filter(contributions, function(contribution) {
      if (!_.isObject(contribution)) {
        console.error("Invalid contribution data '%s'", JSON.stringify(contribution));
        return false;
      }
      const extensionPoint = extensionPointName || contribution.extensionPoint;
      const extensionPointDef = receivingPlugin.extensionPoints[extensionPoint];
      if (extensionPointDef) {
        // the extension schema may be empty, in which case
        // we don't attempt to validate anything
        return Object.keys(extensionPointDef).length
          ? validateContribution(
            ajv,
            extensionPointDef,
            extensionPointName,
            receivingPlugin.pluginId,
            contribution,
            sourcePluginId
          )
          : true;
      } else {
        console.warn(
          "Plugin '%s' contributes to an unknown extension point '%s/%s'",
          sourcePluginId,
          receivingPlugin.pluginId,
          extensionPoint || "<unspecified>"
        );
        return true;
      }
    });
  }

  function getTargetData(target) {
    const [receivingPluginId, extensionPointName] = target.split("/");
    return {receivingPluginId, extensionPointName};
  }

  function appendContributionData(ajv, pluginConfigs, index) {
    pluginConfigs.forEach(function(config) {
      _.forEach(config.contributions || {}, function(contribs, target) {
        const {receivingPluginId, extensionPointName} = getTargetData(target);
        const receivingPlugin = index[receivingPluginId];
        if (receivingPlugin) {
          validContributions(
            ajv,
            receivingPlugin,
            extensionPointName,
            config.pluginId,
            _.flatten([contribs || []])
          ).forEach(function(contribution) {
            if (extensionPointName) contribution.extensionPoint = extensionPointName;
            const contrib = {sourcePluginId: config.pluginId};
            Object.defineProperty(contrib, "contribution", {
              enumerable: true,
              get: _.memoize(
                internationalize.bind(null, contribution, config, window._i18n.locale)
              )
            });
            receivingPlugin.contributions.push(contrib);
          });
        } else {
          console.warn(
            "Plugin '%s' contributes to an unknown plugin id '%s'",
            config.pluginId,
            receivingPluginId
          );
        }
      });
    });
  }

  return function buildContributionIndex(pluginConfigs) {
    const ajv = new Ajv();
    ajv.addSchema(pluginSchema);
    appendValidSchemaDefinitions(ajv, pluginConfigs);
    const index = {};
    pluginConfigs.forEach(function(config) {
      index[config.pluginId] = {
        pluginId: config.pluginId,
        baseUrl: config.baseUrl,
        modulePreload: config.modulePreload || {},
        extensionPoints: validExtensionPointDefs(ajv, config || {}),
        contributions: [],
        title: config.title || ""
      };
    });
    appendContributionData(ajv, pluginConfigs, index);
    return index;
  };
});
