define([
  'jquery',
  'i18n!./nls/i18_extension'
],
function($, i18nMessageBundle) {
  'use strict';

	/* Regex validators */
	var regexValidators  = {
		alphanumeric: {
		  regex: /^[0-9A-Za-z]+$/,
		  message: i18nMessageBundle.digits_letters_only
		},
		number: {
		  regex: /^\d+$/,
		  message: i18nMessageBundle.digits_only
		},
		numberSpace: {
		  regex: /^[\d\ ]+$/,
		  message: i18nMessageBundle.digits_and_spaces_only
		}
	};

	$.fn.extend({
		/* Enter trigger on input element */
	  enter: function(fn) {
	  	$(this).keyup(function(e) {
	  		if (e.keyCode == 13) {
	  			fn();
	  		}
	  	});
	  	return this;
	  },
	  hoverNote: function(str) {
	  	$(this).hover(function() {
	  		footnote(str);
	  	}, function() { footnote(""); });
	  },
	  grab: function(selector) {
		return $(this).find(selector).val();
	  },
	  set: function(selector, val) {
		$(this).find(selector).value(val);
		return this;
	  },
	  toggle: function() {
		var $elem = this;
		var firstFn = arguments[0] || function() {};
		var secondFn = arguments[1] || function() {};
		var state = $elem.data('state');

		switch(state) {
		  case 1:
		  case undefined:
			$elem.data('state', 2);
			firstFn();
		  break;
		  case 2:
			$elem.data('state', 1);
			secondFn();
		  break;
		}
		return this;
	  },
	  serializer : function(options) {
		var x = new Array();

		var s = $.extend( {json: false}, options );

		$.each($(this), function(k, v) {
			var a	=	$(v)
        				.find('input[name],select[name],textarea[name]').serialize();

			if(a) {
				var data = $(v).data();
				var newObj = validate(a, data);

				x.push(newObj);
		  }
		  else {
				console.warn("Found a input without a name tag, will not be able to process that input!! ");
		  }

		});

		//output JSON or Obj
		if(s.json) {
		  return JSON.stringify(x);
		}
		else {
		  return x;
		}

		function validate(serialized, data){

			var str = serialized.split('=');
			var val = $.trim(decodeURIComponent(str[1]));
			var obj = {};

			//Raise a Empty flag based on whether it's required or not
			if( 'required' in data )  {
				obj["empty"]		= 	val ? false : true;
				obj["required"]	=	true;
				delete data['required'];
		 	}

			//Raise a validation flag based on applying regex validator
			if( 'validationRule' in data ) {
				// grab data-validate attr
				var attr  =  data['validationRule'].toLowerCase();

				// find and the regex from regexValidators
				var rule = regexValidators[attr];

				if(rule){
					obj["validated"]      	= rule.regex.test(val) ? true : false;
					obj["validationRule"]  	= data['validationRule'];
					delete data['validationRule'];
				}
			}
		  obj["data"] = data;
		  obj["value"] = val;
		  obj["name"] = str[0];

		  return obj;
	  }
	}
  });

});