define({
    "hide_package":"\u9690\u85cf\u8f6f\u4ef6\u5305",
    "show_package":"\u663e\u793a\u8f6f\u4ef6\u5305",
    "fetch_package_problem":"\u83b7\u53d6\u8f6f\u4ef6\u5305\u5217\u8868\u65f6\u51fa\u73b0\u95ee\u9898",
    "dna_center":"Cisco DNA Center",
    "appversion":"\u7248\u672c",
    "release_note":"\u7248\u672c\u8bf4\u660e",
    "copyright": "2018 \u601d\u79d1\u7cfb\u7edf\u516c\u53f8\u3002\u7248\u6743\u6240\u6709\u3002"
});

