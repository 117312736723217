define([
  'jquery',
  "core/utils/GVTask"
], function($, GVTask) {

  return {

    addPolicyTagAssociation: function(tag, devices) {
      if (!(devices instanceof Array)) {
        devices = [devices];
      }
      var data = {
        policyTag: tag,
        networkDevices: devices
      };
      var dfd = new $.Deferred();
      var addPolicy = new GVTask("/policy/tag/association", {
        type: "POST"
      });
      addPolicy.onsuccess = function(status)  {
        dfd.resolve(status);
      };

      addPolicy.onfail = function(status, tree) {
        dfd.reject(status);
      };
      
      addPolicy.start(data);
      return dfd.promise();
    },

    getPolicyTagAssociationDeviceId: function(device) {
      var dfd = new $.Deferred();
      $.ajax({
        type: 'GET',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/policy/tag/association?networkDeviceId=" + device,
        dataType: "json",
        success: function(response) {
          dfd.resolve(response.response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },

    getPolicyTags: function() {
      var dfd = new $.Deferred();
      $.ajax({
        type: 'GET',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/policy/tag",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response.response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },

    deletePolicyTagAssociation: function(deviceID, tag) {
      var dfd = new $.Deferred();
      var addPolicy = new GVTask("/policy/tag/association?policyTag=" + tag + "&networkDeviceId=" + deviceID, {
        type : "DELETE"
      });
      addPolicy.onsuccess = function(status)  {
        dfd.resolve(status);
      };
      addPolicy.onfail = function(status, tree) {
        dfd.reject(status);
      };
      addPolicy.startDelete();
      return dfd.promise();
    },

    getPolicyTagAssociation: function(offset, limit) {
      var dfd = new $.Deferred();
      $.ajax({
        type: 'GET',
        contentType: "application/json",
        url: window.API_URL_PREFIX + "/policy/tag/association?offset=1&limit=500",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response.response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    },

    addPolicyTag: function(tag) {

      var data = {
        policyTag: tag,
      };

      var dfd = new $.Deferred();
      var addPolicy = new GVTask("/policy/tag", {
        type: "POST"
      });
      addPolicy.onsuccess = function(status)  {
        dfd.resolve(status);
      };
      addPolicy.onfailure = function(status, tree) {
        dfd.reject(status);
      };
      addPolicy.onerror = function(status, tree) {
        dfd.reject(status);
      };
      addPolicy.start(data);
      return dfd.promise();
    },

    deletePolicyTag: function(tag) {
      var dfd = new $.Deferred();
      var addPolicy = new GVTask("/policy/tag?policyTag=" + tag, {
        type: "DELETE"
      });
      addPolicy.onsuccess = function(status)  {
        dfd.resolve(status);
      };
      addPolicy.onfail = function(status, tree) {
        dfd.reject(status);
      };
      addPolicy.startDelete();
      return dfd.promise();
    }



    /*addPolicyTagAssociation: function(tag, devices) {
      var dfd = new $.Deferred();
      if (!(devices instanceof Array)) {
        devices = [devices];
      }
      var post = {
        policyTag: tag,
        networkDeviceIds: devices
      };
      $.ajax({
        data: JSON.stringify(post),
        type: 'POST',
        contentType: "application/json",
        url:  window.API_URL_PREFIX + "/policy/tag/association",
        dataType: "json",
        success: function(response) {
          dfd.resolve(response);
        },
        error: function(error) {
          dfd.reject(error);
        }
      });
      return dfd.promise();
    }*/

  };

});
